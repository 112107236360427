<span class="logo" >
    <img src="assets/infoLogo.png">
  </span>
<h1>Stanka Stojkov</h1>
<div class="col-lg-12  instructors-table">
    <img class="profile-image" src="assets/index4.jpg" alt="..." class="img-thumbnail">
  </div>
<div class="container card-body-main">
  <p>Zdravo, ja sam Stanka Stojkov, rođena sam i odrasla u Beogradu.</p>
  <p>Oduvek su me interesovale alternativne i holističke metode pristupa fizičkom i emocionalno-mentalnom zdravlju osobe.</p>
  <p>U mladosti me je avanturistički duh vodio u različite zemlje, neke bliže neke dalje. Tako sam u Londonu živela skoro 2 godine, u Kolumbiji sam boravila dva puta po 3 meseca odlazeći na Amazon i Karibe,
     u Pretoriji, Južnoafrička republika, sam živela i radila 2 godine. Boraveći u ovim zemljama upoznavala sam se sa različitim ljudima, kulturama, običajima, njihovim interesovanjima i navikama.</p>
  
  <p>Kada sam se napokon smirila u Srbiji, moj avanturistički duh je zaokupio rad na sebi kroz različite metode i duhovnost. 
    I život nam uvek ukaže na metodu i osobu koja nam je potrebna u tom trenutku i za tu stepenicu samorazvoja. Tako sam se na svom životnom i duhovnom putovanju upoznala sa više tehnika i metoda. Neću ih sve nabrajati,
     samo one sa kojima se bavim i koje primenjujem u svom radu, a to su Reiki, Bahove kapi, Bowen, Sotai koji je dopuna Bowenu, Rejuvans. Sve ove metode međusobno se dopunjuju i čine funkcionalnu celinu. U svom praktičnom radu, zajedno sa klijentom,
      procenjujemo i odlučujemo kada ćemo šta primeniti kako bismo u datom trenutku postigli maksimalne rezultate</p>

  <p>Evo kako je teklo moje upoznavanje sa tim metodama i tehnikama:</p>
  <ul>
    <li> Reiki praktičar sam od 2000.god, inicijaciju i obuku sam prošla kod Olivere Turčinov, poznatije kao Olivera Ene.</li>
    <li> Bowen obuku sam započela 2010.god. kod Prof. Andrew Zopposa, senior instruktora i počasnog doživotnog člana BTAA.</li>
    <li> Master terapeut Bowen tehnike sam od 2014.god, obuku sam završila kod Prof. Andrew Zopposa.</li>
    <li> Bahov praktičar sam od 2015.god, petnaestomesečnu obuku sam završila kod Milene Kostić.</li>
    <li> Od 2017.god. sam organizator Bowen i Rejuvance seminara za Srbiju.</li>
    <li> Sotai praktičar sam od 2018.god, učila sam kod Alexandre Antoniou.</li>
    <li> Rejuvance praktičar sam od 2019.god, učila sam kod Alexandre Antoniou.</li>
    <li> Od proleća 2020 god. sam sertifikovani Bowen instruktor od strane Australijske Akademije Bowen tehnike (BTAA) i od tada podučavam osnovne seminare.</li>

  </ul>
  <p>Jednostavnost i izuzetni efekti Bowen tehnike na telo su me opčinili od prvog susreta sa njom i to je razlog moje potpune posvećenosti Bowenu i želji da što više ljudi sazna i nauči ovu tehniku.</p>
  <br>
  <!--<div class="col-lg-12 col-md-12 col-sm-12 col-12 card-img">
      <img src="assets/index3.png">
    </div>-->
</div>
