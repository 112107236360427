import { AboutUs } from './about-us.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AboutUsServices {
  constructor(private http: HttpClient) {

  }

  postContents(postData: AboutUs) {
    console.log("postData", postData.content);
    return this.http.post<{ [key: string]: AboutUs }>('http://127.0.0.1:8000/api/quote', postData);
  }
  getContents(): Observable<any> {
    return this.http.get<{ [key: string]: AboutUs }>('http://127.0.0.1:8000/api/quotes')
      .pipe(map(responseData => {
        const postsArray: AboutUs[] = [];
        for (const key in responseData.quotes) {
          if (responseData.quotes.hasOwnProperty(key)) {
            postsArray.push({ ...responseData.quotes[key], key });
          }
        }
        return postsArray;
      })
      )
  }



  getEditContent( postData: AboutUs) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.put('http://127.0.0.1:8000/api/quote/' + postData.id, postData, { headers});
  }

  deleteContent(id: number) {
    return this.http.delete<{ [id: number]: AboutUs }>('http://127.0.0.1:8000/api/quote/' + id);
  }
}
