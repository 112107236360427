<span class="logo" >
  <img src="assets/logoRej.jpg">
</span>
<router-outlet></router-outlet>
<div class="info-div">
  <div class="col-md-12">
      <img src="assets/logoRejPng.png" class="info-logo" >
      <h1><span routerLink="/kontakt">PRIJAVITE SE ZA SEMINAR</span></h1>

    <h2>060/014-99-49</h2>

  </div>

</div>
