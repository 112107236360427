<div>
  <br>
</div>
<div class="slider">
<ngb-carousel *ngIf="images">
  <ng-template ngbSlide *ngFor="let image of images" >
    <div class="picsum-img-wrapper">
      <img src="{{'assets/slider/'+image}}" alt="Random first slide">
    </div>
     <!--<div class="carousel-caption">
     <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </div>-->
  </ng-template>

</ngb-carousel>
</div>
<br>
<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <br>
        <div class="card" >
            <img src="assets/index1.png" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Bowen</h5>
              <p class="card-text">Nežan, neinvazivan tretman koji deluje na celo telo, delujući prvenstveno na uzrok zdravstvenog problema, pre nego na njegove simptome, koristeći prirodnu sposobnost tela za isceljenje.</p>
              <a routerLink="/bowen-tehnika" class="btn btn-success">Pročitaj opširnije</a>
            </div>
          </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
      <br>
      <div class="card" >
          <img src="assets/index3.png" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">BTAA</h5>
            <p class="card-text">Osnivač i direktor BTAA, gospodin Osvald Renč (Oswald Rentsch) je tehniku učio direktno od Toma Bowena i sa svojom suprugom Elen (Elaine) podučavaju je u njenom originalnom obliku.</p>
            <a  routerLink= "/seminari/btaa" class="btn btn-success">Pročitaj opširnije</a>
          </div>
        </div>

    </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <br>
          <div class="card">
              <img src="assets/index5.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">Stanka Stojkov</h5>
                <p class="card-text"> Bowen tehnikom se bavim od 2010.god. Član sam Udruženja Bowen terapeuta Srbije. Od 2020 god. sam BTAA sertifikovani Bowen instruktor i podučavam osnovne seminare. Takođe sam organizator Bowen i Rejuvance seminara za Srbiju.</p>
                <a routerLink="/stanka" class="btn btn-success">Pročitaj opširnije</a>
              </div>
            </div>

        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <br>
          <div class="card">
              <img src="assets/index2.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">Rejuvance</h5>
                <p class="card-text">Vratite svom licu mladalački izgled, a svom duhu podarite mir, na prirodan, neinvazivan, a istovremeno izuzetno efikasan način. Bez upotrebe krema, seruma, lasera ili električne energije.</p>
                <a routerLink="/rejuvance" class="btn btn-success">Pročitaj opširnije</a>
              </div>
            </div>
  
        </div>

</div><br><br>

<div class="info-div">
  <div class="col-md-12">
      <img src="assets/infoLogo.png" class="info-logo" >
      <h1>ZAKAŽITE TRETMAN</h1>

    <p>Ukoliko vas interesuje neki od tretmana, svoj termin možete zakazati putem telefona:</p>
    <h2>060/014-99-49</h2>

  </div>

</div>
