<div  class="logout">
  <a (click)="onLogout()">Izloguj se!</a>
</div>

<div class="col-lg-12" *ngIf="isLoggedIn">
  <div class="col-lg-12 bg-info table-forms">
    <br>
    <h2>Predstojeći Seminari</h2>
    <div class="table-responsive">
      <table class="table table-bordered">
        <app-seminar *ngFor="let seminar of seminars " [seminar]="seminar" (seminarDeleted)="onDeleted($event)">
        </app-seminar>
      </table>
    </div>
    <br>
    <div *ngIf="!isFormShown">
      <button class="btn btn-light" (click)="onShowForm()">Dodaj Seminar</button>
    </div>
    <div *ngIf="isFormShown">
      <button class="btn btn-light" (click)="onHideForm()">Odustani</button>
    </div>
    <br>
  </div>

  <div class="col-lg-12 form-container table-forms" *ngIf="isFormShown">
    <form class="card" [formGroup]="seminarsForm" (ngSubmit)="onSubmit()">
      <div class="form-body bg-info">
        <div class="form-header">
          <h2>Dodaj Seminar</h2>
          <span class="alert alert-success"
            *ngIf="isSeminarFormSubmited">Uspešno ste dodali seminar!</span>
        </div>
        <div class="form-group ">
          <div class="col-lg-12 admin-form-input">
            <span class="help-block"
              *ngIf="!seminarsForm.get('title').valid && seminarsForm.get('title').touched"></span>
              <select class="form-control" id="title" formControlName="title">
                <option value="Seminari Bowen" >Seminari Bowen</option>
                <option value="Posebni Seminari">Posebni Seminari</option>
                <option value="Rejuvance">Rejuvance</option>
              </select>

          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="town" id="town" placeholder="Grad">
            <span class="help-block"
              *ngIf="!seminarsForm.get('town').valid && seminarsForm.get('town').touched"></span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="seminars" id="seminars" placeholder="Seminari">
            <span class="help-block"
              *ngIf="!seminarsForm.get('seminars').valid && seminarsForm.get('seminars').touched"></span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="group" id="group" placeholder="Broj grupa">
            <span class="help-block"
              *ngIf="!seminarsForm.get('group').valid && seminarsForm.get('group').touched"></span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="instructors" id="instructors"
              placeholder="Instruktori">
            <span class="help-block"
              *ngIf="!seminarsForm.get('instructors').valid && seminarsForm.get('instructors').touched"></span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="date" id="date" placeholder="Datum">
            <span class="help-block"
              *ngIf="!seminarsForm.get('date').valid && seminarsForm.get('date').touched"></span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" class="form-control" formControlName="time" id="time" placeholder="Vreme">
            <span class="help-block"
              *ngIf="!seminarsForm.get('time').valid && seminarsForm.get('time').touched"></span>
          </div>


          <br>
          <div class="submit-button">
            <button type="submit" class="btn btn-success" [disabled]="!seminarsForm.valid">Dodaj Seminar</button>
          </div>


        </div>
      </div>
    </form>

  </div>


  <hr>

  <div class="col-lg-12 bg-info table-forms">
    <br>
    <h2>Instruktori</h2>
    <div class="table-responsive">
      <table class="table table-bordered">
        <app-instructor *ngFor="let instructor of instructors " [instructor]="instructor" (instructorDeleted)="onDeletedInstructor($event)">
        </app-instructor>
      </table>
    </div>
    <br>
    <div *ngIf="!isInstructorsFormShown">
      <button class="btn btn-light" (click)="onShowInstructorsForm()">Dodaj Instruktora</button>
    </div>
    <div *ngIf="isInstructorsFormShown">
      <button class="btn btn-light" (click)="onHideInstructorsForm()">Odustani</button>
    </div>
    <br>
  </div>
  <div class="col-lg-12 form-container table-forms" *ngIf="isInstructorsFormShown">
      <form class="card" [formGroup]="instructorsForm" (ngSubmit)="onSubmitTherapist()">
        <div class="form-body bg-info">
          <div class="form-header">
            <h2>Dodaj Instruktora</h2>
          </div>
          <span class="alert alert-success"
            *ngIf="isFormSubmitSuccess">Uspešno ste dodali instruktora!</span>
          <div class="form-group ">

            <div class="col-lg-12 admin-form-input">
              <input type="text" name="name" formControlName="name" class="form-control" id="name" placeholder="Ime">
              <span class="help-block"
                *ngIf="!instructorsForm.get('name').valid && instructorsForm.get('name').touched">Popunite polje za ime!</span>
            </div>
            <div class="col-lg-12 admin-form-input">
                <textarea type="text" name="content" formControlName="content" class="form-control" id="content" placeholder="Opis" rows="6"></textarea>
              <span class="help-block"
                *ngIf="!instructorsForm.get('content').valid && instructorsForm.get('content').touched">Popunite polje!</span>
            </div>
            <div class="col-lg-12 admin-form-input">
                <input type="file" name="image" formControlName="image" class="form-control" id="image" (change)="onFileSelected($event)">
              <span class="help-block"
                *ngIf="!instructorsForm.get('image').valid && instructorsForm.get('image').touched">Izaberite sliku!</span>
            </div>
            <br>
            <div class="submit-button">
                <button type="submit" class="btn btn-success" [disabled]="!instructorsForm.valid">Dodaj Terapeuta</button>
            </div>
          </div>
        </div>
      </form>

    </div>


    <div class="col-lg-12 bg-info table-forms">
      <br>
      <h2>Događaji</h2>
      <div class="table-responsive">
        <table class="table table-bordered">
          <app-event *ngFor="let event of events " [event]="event" (eventDeleted)="onDeletedEvent($event)">
          </app-event>
        </table>
      </div>
      <br>
      <div *ngIf="!isEventsFormShown">
        <button class="btn btn-light" (click)="onShowEventsForm()">Dodaj Događaji</button>
      </div>
      <div *ngIf="isEventsFormShown">
        <button class="btn btn-light" (click)="onHideEventsForm()">Odustani</button>
      </div>
      <br>
    </div>
    <div class="col-lg-12 form-container table-forms" *ngIf="isEventsFormShown">
      <p>**Za prebacivanje teksta u novi red za rubriku 'detalji' koristiti -->>> ';' **</p>
        <form class="card" [formGroup]="eventsForm" (ngSubmit)="onSubmitEvents()">
          <div class="form-body bg-info">
            <div class="form-header">
              <h2>Dodaj događaji</h2>
            </div>
            <span class="alert alert-success"
              *ngIf="isEventsFormSubmited">Uspešno ste dodali događaji!</span>
            <div class="form-group ">
  
              <div class="col-lg-12 admin-form-input">
                <input type="text" name="title" formControlName="title" class="form-control" id="title" placeholder="Naslov">
                <span class="help-block"
                  *ngIf="!eventsForm.get('title').valid && eventsForm.get('title').touched">Popunite polje za naslov!</span>
              </div>
              <div class="col-lg-12 admin-form-input">
                  <input type="text" name="place" formControlName="place" class="form-control" id="place" placeholder="Mesto" >
                <span class="help-block"
                  *ngIf="!eventsForm.get('place').valid && eventsForm.get('place').touched">Popunite polje!</span>
              </div>
              <div class="col-lg-12 admin-form-input">
                <textarea type="text" name="short_detail" formControlName="short_detail" class="form-control" id="short_detail" placeholder="Kratak detalj" rows="6"></textarea>
              <span class="help-block"
                *ngIf="!eventsForm.get('short_detail').valid && eventsForm.get('short_detail').touched">Popunite polje!</span>
            </div>
              <div class="col-lg-12 admin-form-input">
                <textarea type="text" name="detail" formControlName="detail" class="form-control" id="detail" placeholder="Detalji" rows="6"></textarea>
              <span class="help-block"
                *ngIf="!eventsForm.get('detail').valid && eventsForm.get('detail').touched">Popunite polje!</span>
            </div>
              <div class="col-lg-12 admin-form-input">
                <input type="text" name="date" formControlName="date" class="form-control" id="date" placeholder="Datum">
                <span class="help-block"
                  *ngIf="!eventsForm.get('date').valid && eventsForm.get('date').touched">Popunite polje za datum!</span>
              </div>
              <div class="col-lg-12 admin-form-input">
                <input type="text" name="time" formControlName="time" class="form-control" id="time" placeholder="Vreme">
                <span class="help-block"
                  *ngIf="!eventsForm.get('time').valid && eventsForm.get('time').touched">Popunite polje za vreme!</span>
              </div>
              <div class="col-lg-12 admin-form-input">
                  <input type="file" name="image" formControlName="image" class="form-control" id="image" (change)="onFileEventSelected($event)">
                <span class="help-block"
                  *ngIf="!eventsForm.get('image').valid && eventsForm.get('image').touched">Izaberite sliku!</span>
              </div>
              <br>
              <div class="submit-button">
                  <button type="submit" class="btn btn-success" [disabled]="!eventsForm.valid">Dodaj događaji</button>
              </div>
            </div>
          </div>
        </form>
  
      </div>
<!-- Videos-->
     <div class="col-lg-12 bg-info table-forms">
        <br>
        <h2>Video</h2>
        <div class="table-responsive">
          <table class="table table-bordered">
            <app-video-admin *ngFor="let video of videos " [video]="video" (videoDeleted)="onDeletedVideo($event)">
            </app-video-admin>

            <!--<div class="col-lg-12" >
              <div class="row text-center ">
                <div class="col-lg-3 col-md-4 col-sm-12" *ngFor="let video of videosProtectUrl">
                  <iframe class="video-layout" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
            
                  [src]='video'>
                  </iframe>
                </div>
                
               
              </div>
            </div>-->


          </table>
        </div>
        <br>
        <div *ngIf="!isVideosFormShown">
          <button class="btn btn-light" (click)="onShowVideosForm()">Dodaj Video</button>
        </div>
        <div *ngIf="isVideosFormShown">
          <button class="btn btn-light" (click)="onHideVideosForm()">Odustani</button>
        </div>
        <br>
      </div>
      <div class="col-lg-12 form-container table-forms" *ngIf="isVideosFormShown">
          <form class="card" [formGroup]="videosForm" (ngSubmit)="onSubmitVideos()">
            <div class="form-body bg-info">
              <div class="form-header">
                <h2>Dodaj Video</h2>
              </div>
              <span class="alert alert-success"
                *ngIf="isVideosFormSubmited">Uspešno ste dodali video!</span>
              <div class="form-group ">
    
                <div class="col-lg-12 admin-form-input">
                  <input type="text" name="video_url" formControlName="video_url" class="form-control" id="video_url" placeholder="Video url">
                  <span class="help-block"
                    *ngIf="!videosForm.get('video_url').valid && videosForm.get('video_url').touched">Popunite polje za url!</span>
                </div>
             
                <br>
                <div class="submit-button">
                    <button type="submit" class="btn btn-success" [disabled]="!videosForm.valid">Dodaj video</button>
                </div>
              </div>
            </div>
          </form>
    
        </div>

<!--PRAKTICARI-->

<div class="col-lg-12 bg-info table-forms">
  <br>
  <h2>Praktičari</h2>
  <div class="table-responsive">
    <table class="table table-bordered">
      <app-practitioner *ngFor="let practitioner of practitioners " [practitioner]="practitioner" (practitionerDeleted)="onDeletedPractitioner($event)">
      </app-practitioner>
    </table>
  </div>
  <br>
  <div *ngIf="!isPractitionersFormShown">
    <button class="btn btn-light" (click)="onShowPractitionersForm()">Dodaj Praktičara</button>
  </div>
  <div *ngIf="isPractitionersFormShown">
    <button class="btn btn-light" (click)="onHidePractitionersForm()">Odustani</button>
  </div>
  <br>
</div>
<div class="col-lg-12 form-container table-forms" *ngIf="isPractitionersFormShown">
    <form class="card" [formGroup]="practitionersForm" (ngSubmit)="onSubmitPractitioner()">
      <div class="form-body bg-info">
        <div class="form-header">
          <h2>Dodaj Praktičara</h2>
        </div>
        <span class="alert alert-success"
          *ngIf="isPractitionersFormSubmited">Uspešno ste dodali praktičara!</span>
        <div class="form-group ">

          <div class="col-lg-12 admin-form-input">
            <input type="text" name="name" formControlName="name" class="form-control" id="name" placeholder="Ime">
            <span class="help-block"
              *ngIf="!practitionersForm.get('name').valid && practitionersForm.get('name').touched">Popunite polje za ime!</span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" name="phone" formControlName="phone" class="form-control" id="phone" placeholder="telefon">
            <span class="help-block"
              *ngIf="!practitionersForm.get('phone').valid && practitionersForm.get('phone').touched">Popunite polje za telefon!</span>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="text" name="email" formControlName="email" class="form-control" id="email" placeholder="E-Mail">
            <span class="help-block"
              *ngIf="!practitionersForm.get('email').valid && practitionersForm.get('email').touched">Popunite polje za e-mail!</span>
          </div>
          <div class="col-lg-12 admin-form-input">
          <div class="form-group" >
            <label style="margin-right: 4px">Zemlja: </label>
                <select class="form-control" id="country" formControlName="country">
                  <option *ngFor="let country of countries">
                    {{country}}
                 </option>
                </select>
          </div>
          </div>
          <div class="col-lg-12 admin-form-input">
            <div class="form-group">
              <label style="margin-right: 4px">Grad: </label>
                <select class="form-control" name="city" id="city" formControlName="city">
                  <option *ngFor="let city of cities">
                    {{city}}
                 </option>
                </select>
                </div>
          </div>
          <div class="col-lg-12 admin-form-input">
            <div class="form-group">
              <label style="margin-right: 4px">Grad2: </label>
                <select class="form-control" name="city2" id="city2" formControlName="city2">
                  <option *ngFor="let city of cities">
                    {{city}}
                 </option>
                </select>
                </div>
          </div>
          <div class="col-lg-12 admin-form-input">
            <input type="checkbox" name="visible" formControlName="visible" class="form-control" id="visible">
            <label> Placena clanarina</label><br>
          </div>
          <br>
          <div class="submit-button">
              <button type="submit" class="btn btn-success" [disabled]="!practitionersForm.valid">Dodaj Praktičara</button>
          </div>
          </div>
          
      </div>
    </form>

  </div>

<!--Drzava / Grad-->

<div class="col-lg-12 bg-info table-forms">
  <br>
  <h2>Drzava / Grad</h2>
  <div class="table-responsive">
    <table class="table table-bordered">
      <app-place *ngFor="let place of places " [place]="place" (placeDeleted)="onDeletedPlace($event)">
      </app-place>
    </table>
  </div>
  <br>
  <div *ngIf="!isPlacesFormShown">
    <button class="btn btn-light" (click)="onShowPlacesForm()">Dodaj Grad</button>
  </div>
  <div *ngIf="isPlacesFormShown">
    <button class="btn btn-light" (click)="onHidePlacesForm()">Odustani</button>
  </div>
  <br>---
</div>
<div class="col-lg-12 form-container table-forms" *ngIf="isPlacesFormShown">
  <form class="card" [formGroup]="placesForm" (ngSubmit)="onSubmitPlaces()">
    <div class="form-body bg-info">
      <div class="form-header">
        <h2>Dodaj Grad</h2>
      </div>
      <span class="alert alert-success"
        *ngIf="isPlacesFormSubmited">Uspešno ste dodali Grad!</span>
      <div class="form-group ">
        <div class="col-lg-12 admin-form-input">
          <input type="text" name="country" formControlName="country" class="form-control" id="country" placeholder="Zemlja">
          <span class="help-block"
            *ngIf="!placesForm.get('country').valid && placesForm.get('country').touched">Popunite polje za drzavu!</span>
        </div>
        <div class="col-lg-12 admin-form-input">
          <input type="text" name="city" formControlName="city" class="form-control" id="city" placeholder="Grad">
          <span class="help-block"
            *ngIf="!placesForm.get('city').valid && placesForm.get('city').touched">Popunite polje za grad!</span>
        </div>
        <div class="submit-button">
          <button type="submit" class="btn btn-success" [disabled]="!placesForm.valid">Dodaj Grad</button>
        </div>
      </div>
    </div>
  </form>

  </div>
</div>
