import { AuthService } from './../login/auth.service';
import { AboutUsServices } from './about-us.service';
import { AboutUs } from './about-us.interface';
import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit, OnChanges {
  isDeleted: boolean = false;
  isEdit: boolean = false;
  isLoggedIn: boolean = false;
  editValue: string;
  quotesForm: FormGroup;
  quotes: AboutUs[] = [];
  constructor(private aboutUsServices: AboutUsServices, private authService: AuthService) { }

  ngOnInit() {
    this.onGetContent();
    this.quotesForm = new FormGroup({
      'id': new FormControl(null, Validators.required),
      'content': new FormControl(null, Validators.required),
    });

    if(this.authService.loggedIn) {
      this.isLoggedIn = true;
      // console.log("[mj]-AboutUsLoggedIN",this.authService.loggedIn);
    }else{
      this.isLoggedIn = false;
      // console.log("[mj]-AboutUsLoggedIN",this.authService.loggedIn);
    }
  }
  ngOnChanges() {
  }

  onSubmit() {
    this.aboutUsServices.postContents(this.quotesForm.value).subscribe(
      responseData => {
        this.quotesForm.reset();
        this.onGetContent();
      }
    );
  }

  onGetContent() {
    this.aboutUsServices.getContents()
      .subscribe(data => {
        this.quotes = data;
      });
    //console.log(this.quotes);
  }
  onEdit(quote: AboutUs) {
    this.isEdit = true;
    this.editValue = quote.content;

  }
  onDeleted(quote: AboutUs) {
    const position = this.quotes.findIndex((quoteEl: AboutUs) => {
      return quoteEl.id == quote.id;
    });
    this.quotes.splice(position, 1);
  }
}
