<h1>Događaji</h1>

<div class="container">
<div class="col-lg-12" *ngFor="let event of events" >
    <div class="row text-center ">
      <div class="col-lg-12 col-md-12 col-sm-12 instructors-table card ">
        <div class="row">

        <div class="col-lg-6 col-md-6 col-sm-6 instructors-table">
            <img class="profile-image" src="{{event.image_url}}" alt="..." class="img-thumbnail">
            <p class="text-below-image">{{event.short_detail}}</p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 content ">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <h3>{{event.title}}</h3>
                  <hr>
                </div>
               
              </div>
             
                  <div class="row">
                      <div class="col-lg-2 icons">
                          <i class="fa fa-clock-o fa-2x" aria-hidden="true"></i>
                      </div>
                      <div  class="col-lg-10" align="left">
                        <p class="detail-text">{{event.date}} - {{event.time}}</p>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-2 icons">
                          <i class="fa fa-map-marker fa-2x" aria-hidden="true"></i>
                      </div>
                      <div  class="col-lg-10" align="left">
                        <p class="detail-text">{{event.place}}</p>
                      </div>
                  </div>
                  <div class="submit-button event-button">
                    <button type="button" class="btn btn-layout" [routerLink]="['/novosti/detalji-dogadjaja', event.title]" >Detaljnije</button>
                  </div>
        </div>
      
     
      </div>
        
      </div>
    </div>
  </div>
</div>