<div class="table-responsive">
    <table class="table table-bordered practitionerTable">
      <thead>
        <tr class="bg-info">
          <th scope="col">Zemlja</th>
          <th scope="col">Grad</th>
          <th scope="col">Izmene</th>
        </tr>
  
      </thead>
  
  
      <tbody>
        <tr>
          <td>{{place.country}}
            <div *ngIf="isEdit">
              <input type="text" [(ngModel)]="editValues.country"><br>
            </div>
          </td>
          <td>{{place.city}}
            <div *ngIf="isEdit">
              <input type="text" [(ngModel)]="editValues.city"><br>
            </div>
          </td>
          <td>
            <!-- <div class="buttons"> -->
              <div *ngIf="isEdit">
                <button class="btn btn-info" (click)="onSave(place.id)">Sačuvaj</button>
                <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
              </div>
              <div *ngIf="!isEdit">
                <button class="btn btn-danger" (click)="onDelete(place.id)">Obriši</button>
                <button class="btn btn-success button_padding" (click)="onEdit(place)">Izmeni</button>
              </div>
  
            <!-- </div> -->
          </td>
        </tr>
  
      </tbody>
  
  
    </table>
  </div>
  