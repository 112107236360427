import { Component, OnInit, ViewChild, ElementRef, DoCheck, HostListener, OnChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges {

  public isSeminarsActivated: boolean = false;
  public isRejSeminarsActivated: boolean = false;
  public isNewsActivated: boolean = false;
  public navbarOpen = false;

  @ViewChild('navLogo', { static: true }) navLogo: ElementRef;
  @ViewChild('stickyActive', { static: true }) stickyActive: ElementRef;
  @ViewChild('seminars', { static: true }) seminars: ElementRef;
  @ViewChild('bta', { static: true }) bta: ElementRef;

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
   /* if (e.target['scrollingElement'].scrollTop >= this.stickyActive.nativeElement.offsetTop) {
      this.navLogo.nativeElement.style.opacity = '1';
      this.stickyActive.nativeElement.style.background = 'white';

      this.stickyActive.nativeElement.style.boxShadow = ' 4px 3px 9px #9c9c9c';
    } else {
      this.navLogo.nativeElement.style.opacity = '0';
      this.stickyActive.nativeElement.style.background = 'transparent';
      this.stickyActive.nativeElement.style.boxShadow = 'none';
    }
*/
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {  
      this.isSeminarsActivated = false;
      this.isRejSeminarsActivated = false;
      if(this.router.url.indexOf('/seminari') != -1){
        this.isSeminarsActivated = true;
      }
      if(this.router.url.indexOf('novosti') != -1){
        this.isNewsActivated = true;
      }
      if(this.router.url.indexOf('rejuvance-') != -1){
        this.isRejSeminarsActivated = true;
      }
  });
  }

  ngOnChanges() {

  }

  onSeminarsClicked(){
    this.isSeminarsActivated = true;
    this.isNewsActivated = false;
    this.isRejSeminarsActivated = false;
    this.navbarOpen = false;
  }
  onNewsClicked(): void{
    this.isNewsActivated = true;
    this.isSeminarsActivated = false;
    this.isRejSeminarsActivated = false;
    this.navbarOpen = false;
  }
  onRejSeminarsClicked(){
    this.isRejSeminarsActivated = true;
    this.isSeminarsActivated = false;
    this.isNewsActivated = false; 
    this.navbarOpen = false;
  }



  toggleNavbar(): void {
      this.navbarOpen = !this.navbarOpen;
  }


}
