<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="bg-info">
                <th scope="col">Video</th>
                <th scope="col">Video_Url</th>
                <th scope="col">Izmene</th>
            </tr>

        </thead>


        <tbody>
            <tr>
                <td>  <iframe class="video-layout" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
            
                    [src]='videosProtectUrl[0]'>
                    </iframe>
                    <div *ngIf="isEdit">
                    
                    </div>
                </td>
                <td>{{video.video_url}}
                    <div *ngIf="isEdit">
                        <input type="text" [(ngModel)]="editValues.video_url"><br>
                    </div>
                </td>
            
                <td>
                    <div>
                        <div *ngIf="isEdit">
                            <button class="btn btn-info" (click)="onSave(video.id)">Sačuvaj</button>
                            <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
                        </div>
                        <div *ngIf="!isEdit">
                            <button class="btn btn-danger" (click)="onDelete(video.id)">Obriši</button>
                            <button class="btn btn-success button_padding" (click)="onEdit(video)">Izmeni</button>
                        </div>

                    </div>
                </td>
            </tr>

        </tbody>


    </table>
</div>