import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Video } from 'src/app/pages/news/videos/video.interface';
import { VideoServices } from 'src/app/pages/news/videos/video.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-admin',
  templateUrl: './video-admin.component.html',
  styleUrls: ['./video-admin.component.css']
})
export class VideoAdminComponent implements OnInit {

  @Input() video: Video;
  @Output() videoDeleted: EventEmitter<Video> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValues: Video ;
  videosProtectUrl: SafeResourceUrl[] = [];
  constructor(private videoServices: VideoServices, private senitizer: DomSanitizer) { }

  ngOnInit() {
    this.getVideosUrl();
  }
  ngOnChanges() {
  }
  onEdit(event: Video) {
    this.isEdit = true;
    this.editValues = event;
   // this.editValue = quote.content;

  }
  onCancel() {
   // this.editValues = {};
    this.isEdit = false;
  }


  onSave(id: number) {
    //this.event = this.editValues;
    console.log("video", this.video);
    this.videoServices.getEditVideo(this.video).subscribe((responseData: Video) => {
      this.video = this.editValues;
    this.editValues = {
      id: null,
      name:'',
      video_url: ''
      }
    });
    this.isEdit = false;
    this.getVideosUrl();
  }
  onDelete(id: number) {
    this.videoServices.deleteVideo(id).subscribe(
      responseData => {
        this.videoDeleted.emit(this.video);
        console.log("VideoDeleted", responseData);
        this.isDeleted = true;
      }
    )
  }

  getVideosUrl() {
    this.videosProtectUrl.push(this.senitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+ this.video.video_url));
  }
}
