import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
@Injectable()
export class PhotosServices {
    johnWilksomImages = [];
    rejuvanceImages = [];
    bowenImages = [];
    modul7Images = [];
    petsImages = [];
  
  constructor(private http: HttpClient) {

  }
  getVideo(): Observable<NgxGalleryImage[]> {
    return Observable.create(observer => {
    const masterImages: NgxGalleryImage[] = [];
    for(let i=1; i <=6; i++ ){
        masterImages.push({
                small: 'assets/slider/'+i+'.jpg',
                medium: 'assets/slider/'+i+'.jpg',
                big: 'assets/slider/'+i+'.jpg'
            
        });
        console.log('[mj]-Gallery',i);
       /* return masterImages;*/   
    }
    return masterImages;
  })
  }


 /* postVideo( postData: Video) {

  }


  getEditVideo( postData: Video) {

  }

  deleteVideo(id: number) {
  }*/

}
