
  <div class="text-center footer">
    <div class="card-header">
      Copyright © 2020 | Bowen Srbija
    </div>
    <div class="row ">
      <div class="col-lg-4">

      </div>
    <div class="col-lg-4">
      <!-- <div class="icon-floating icon-floating-bck" routerLink="/bowen/login">
        <i class="fas fa-user fa-2x" ></i>
     </div> -->
      <div class="icon-floating icon-floating-bck">
        <a (click)="openSiteinNewTab()"><i class="fa fa-facebook-square fa-3x" aria-hidden="true"></i></a>
     </div>
 <div class="contact-footer">
    <h2>Broj telefona: 060/014-99-49</h2>
   
    <h2>Email: info@bowensrbija.rs</h2>
 </div>
          <br>
    


     
      <br>
<br>
    </div>

    <div class="col-lg-4">

    </div>
    </div>
  </div>



