<h1>Obuka</h1>
<div class="container card-body" >

  <br>
    <p>
        Obuka za Bowen praktičara sastoji se od četiri seminara u trajanju od po četiri dana, koji se organizuju svakih 2 do3 meseca. Vreme između seminara je neophodno da bi se naučeno uvežbalo i praktično primenjivalo pre nastavka obuke na sledećem modulu i kako bi polaznici kompletirali 10 studija slučajeva do modula 7.
        <br>
        <br>
        Prva tri seminara sastoje se od po dva modula, dok se na poslednjem, modulu 7 (oko 9-12 meseci od početka obuke), polaže pismeni i praktični deo ispita kako bi se stekla diploma praktičara, koju izdaje Australijska Akademija Bowen Terapije (BTAA).
        <br>
          <br>

          Za učenje ove tehnike nije neophodno prethodno medicinsko obrazovanje. Ipak, za dobijanje diplome praktičara neophodan je dokaz o položenom ispitu (najmanje 60 časova) Anatomije i fiziologije od strane priznate institucije (srednja medicinska škola ili fakultet, ili pohađanje seminara u našoj organizaciji, koji je akreditovan od strane BTAA) i sertifikat o završenoj obuci Prve pomoći.
            <br>
          <br>
          Na svakom seminaru dobija se priručnik na srpskom jeziku sa detaljnim i sveobuhvatnim objašnjenjima.
          <br>
          <br>
          Da biste sebi osigurali mesto na seminaru, potrebno je uplatiti depozit mesec dana pre početka seminara. U slučaju otkaza neophodno je da obavestite organizatora najkasnije dve nedelje pre početka kursa, u suprotnom depozit se ne vraća.
          <br>
          <br>
          Po pravilima i po zakonu o zaštiti podataka, tokom predavanja se očekuje poštovanje privatnosti svih prisutnih. To znači da nije dozvoljeno snimanje tona/glasa, ni fotografisanje bez prethodnog odobrenja ostalih učesnika/ca za vreme trajanja seminara, bilo da se snimanje odvija profesionalnim uređajima ili mobilnim telefonima. Takođe, komunikacija mobilnim telefonima dozvoljena je u pauzama ili van mesta održavanja predavanja.
          <br>
          <br>
        </p>
          <p align="left"> Za prisustvo na Bowen seminarima potrebno je sledeće:<br><img src="assets/infoLogo.png"> Kratko isečeni nokti (nakon pokazivanja procedura, pokreti se uče tako što vežbate jedni na drugima i zbog tačnog izvodjenja pokreta radi se na goloj koži, pa je važno da ne ostavite ogrebotine na koži kolega/inica)<br><img src="assets/infoLogo.png"> Udobna odeća – šorts ili kupaći kostim, itd.<br><img src="assets/infoLogo.png"> Krejon (za obeležavanje tačnih mesta na kojima se izvode pokreti)<br><img src="assets/infoLogo.png"> Dva velika peškira (ili čaršav i veliki peškir)<br><img src="assets/infoLogo.png"> Sveska za beleške<br><img src="assets/infoLogo.png"> Dobro raspoloženje!
           <br>
            <br>Seminar se održava u Hotelu „1000 ruža“ u vremenu od 9-17h. Pauze u prepodnevnim i po podnevnim časovima, obezbeđuje organizator, dok ste Vi u obavezi da obezbedite sebi ručak za vreme velike pauze. Naravno, u hotelu postoji restoran sa dosta povoljnim cenama ili možete poneti nešto sa sobom.
            <br>
            <br>Vidimo se uskoro! </p>
  </div>
