<h1>Nastavni program</h1>

<div class="container card-body">
  <h2>Australijska Akademija Bowen Terapije</h2>
  <h3>Program obuke</h3>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Moduli 1&2</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>4-dnevni osnovni kurs (32 časa)</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>45.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Moduli 3&4</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>4-dnevni kurs (32 časa)
        <br>
        Revizija Modula 1&2, nove procedure, praktična i pismena procena
        <br>
        Dostaviti 4 studije slučajeva u elektronskoj formi
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>45.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Moduli 5&6</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>4-dnevni kurs (32 časa)
        <br>
        Revizija prethodnih modula, nove procedure, praktična i pismena procena
        <br>
        Dostaviti 4 studije slučajeva u elektronskoj formi
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>45.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 7</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>4-dnevni kurs (32 časa)
        <br>
        Prva 2 dana je revizija svih procedura
        <br>
        Trećeg i četvrtog dana je pismeni ispit i praktična procena
        <br>
        Dostaviti 2 studije slučajeva u elektronskoj formi
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>45.000,00 din</p>
    </div>
  </div>
  <hr>

  <div class="row">
    <p><b>VAŽNO:</b> Ako vreme između seminara sve do modula 7, pređe dvanaest meseci, poslednji seminar koji je
      pohađan, mora da bude obnovljen, gde se obezbeđuje popust od 20% od redovne cene. Neophodni kriterijumi za
      dobijanje diplome Bowen praktičara, od strane Australijske Akademije Bowen Terapije i prijem u članstvo Udruženja
      su:</p>
    <ul>
      <li>1. Završen Modul 7 (128 časova minimum)</li>
      <li>2. Uspešno položeni pismeni i praktični ispit</li>
      <li>3. Dostavljeno 10 studija slučajeva u elektronskoj formi</li>
      <li>4. 60 časova Anatomije i Fiziologije (izuzev za osobe iz medicinske struke)</li>
      <li>5. Sertifikat o završenoj obuci iz Prve pomoći</li>
    </ul>
    <p>Nastava anatomije, fiziologije i prve pomoći je neophodna za sticanje diplome Bowen praktičara. Za sve osobe koje
      nisu iz medicinske struke, obuku drži dr Jasmina Tadić, specijalista patološke anatomije.
      Za detalje i prijavu kontaktirati dr Tadić:
      <br>
      E-mail: <b>saramil777@gmail.com</b>
      Tel.: <b>+381 69 788 377</b>.
    </p>
    <div style="margin: 0 auto;">
      <p>
        Za članstvo u udruženje kontaktirati „Bowtech“ Bowen Udruženje Srbije,
        <br> E-mail: <b>bowenudruzenje@gmail.com</b>, Tel: <b>+381 65 2026857</b>.
      </p>
    </div>
    
    <p>Kao član Udruženja dobijate pravo da vaše ime bude navedeno na sajtu i pravo da pohađate “Napredni” i “Master”
      opcione edukativne kurseve. Kako bi zadržali punopravno članstvo,
      Bowtech praktičari treba da sa B.T.A.A. akreditovanim instruktorom pohađaju minimum 32-časovni kurs (4 dana),
      svake 2 godine, ili 16 časova (dva dana) godišnje tj. 16 CEU (bodovi kontinuirane edukacije) po godini.</p>

  </div>
  <hr>
  <p class="bold-text">Opcioni edukativni kursevi:</p>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 8</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>2-dnevni kurs (16 časa)
        <br>
        Revizija svih procedura iz modula 1-6
        <br>
        Fino podešavanje rada i saveti za poboljšanje Rezultata
        <br>
        <b> Ovaj kurs vas kfalifikuje za 2-dnevni napredni (advanced) S.B.P.1 (modul 9)</b>
      </p>

      <!-- bolduj ovo  -->

    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>15.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 9</p>
      <p class="bold-text">S.B.P.1a</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>2-dnevni kurs (16 časa)- Napredni
        <br>
        Potrebno je da prođe najmanje 9 meseci
        <br>
        Nakon završenog modula 7. Nove procedure
        <!-- bolduj ovo  -->
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>42.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 10</p>
      <p class="bold-text">S.B.P.1b</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>2-dnevni kurs (16 časa)- Napredni
        <br>
        Najmanje 6 meseci nakon modula 9
        <br>
        Revizija modula 9, pismeni ispit i praktična procena
        <br>
        Dostaviti 2 studije slučaja u elektronskoj formi
        <br>
        Dobijanje diplome Naprednog Bowen praktičara i
        <br>
        jedne * pored imena na zvaničnom sajtu
        <!-- bolduj ovo  -->
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>42.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 11</p>
      <p class="bold-text">S.B.P.2a</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>2-dnevni kurs (16 časa)- Master
        <br>
        Najmanje 6 meseci nakon modula 10
        <br>
        Nove procedure
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>54.000,00 din</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p class="bold-text">Modul 12</p>
      <p class="bold-text">S.B.P.2b</p>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
      <p>2-dnevni kurs (16 časa)- Master
        <br>
        Najmanje 6 meseci nakon modula 11
        <br>
        Revizija modula 11, pismeni ispit i praktična procena
        <br>
        Dostaviti 2 studije slučaja u elektronskoj formi
        <br>
        Dobijanje diplome Master Bowen praktičara i
        <br>
        dve * pored imena na zvaničnom sajtu
        <!-- bolduj ovo  -->
      </p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      <p>cena: <br>54.000,00 din</p>
    </div>
  </div>
</div>