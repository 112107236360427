<h1>Instruktori</h1>
<div class="container"  *ngFor="let instructor of instructors">
  <div class="row" *ngIf="instructor.name == 'Alexandra Antoniou****'" >
    <div class="col-lg-2  instructors-table">
      <img class="profile-image" src="{{instructor.image_url}}" alt="..." class="img-thumbnail">
    </div>

    <div class="col-lg-10 card-body-main">
      <h2>Alexandra Antoniou
      </h2>
      <p>Ona je fizioterapeut. Na svojim predavanjima se trudi da prenese znanje na bezbedan i prijateljski način, pružajući pri tom obilje informacija iz anatomije, fiziologije kao i veze sa tradicionalnom kineskom medicinom.</p>
    </div>
  </div>
<br>
</div>
