import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PractitionersServices } from './practitioners.services';
import { Practitioners } from './practitioners.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlacesServices } from 'src/app/shared/places/places.services';

@Component({
  selector: 'app-practitioners',
  templateUrl: './practitioners.component.html',
  styleUrls: ['./practitioners.component.css']
})
export class PractitionersComponent implements OnInit {
  practitioners: Practitioners[] = [];
  countries: string[] = [];
  cities: string[] = [];
  stars = new Map<string, number>();
  currentPage: number = 0;
  pageLimit: number = 20;
  pages: Practitioners[][] = [];
  totalPractitioners: number = 0;
  totalPages: number = 4;
  activePage: number = 0;
  countryForm: FormGroup;
  cityForm: FormGroup;
  isFormSubmited: boolean = false;
  init: boolean = false;
  isCitySelected = false;
  constructor(private practitionersServices: PractitionersServices, private placesServices: PlacesServices) { }

  ngOnInit() {
    this.countryForm = new FormGroup({
      'country': new FormControl('all', Validators.required),
      'city': new FormControl('all', Validators.required),
    });
    this.onChanges();
    this.getPractitionersResponse();
    this.init = true;
  }

  onChanges() {
    this.countryForm.get('country').valueChanges.subscribe(val => {
      this.isCitySelected = false;
    });

    this.countryForm.get('city').valueChanges.subscribe(val => {
      this.isCitySelected = true;
    });

  }

  arrayStar(n: number): any[] {
    return Array(n);
  }

  getActivePage(n: number): any[] {
    return this.pages[n];
  }

  onActivePage(event: number) {
    this.activePage = event;
    this.currentPage = event;
  }

  onSubmitCountryForm() {
    this.createPractitioners();
    if (this.isCitySelected) {
      if (this.countryForm.value.city.toLowerCase() !== 'all') {
        let filteredPages: Practitioners[][] = [];
        let filteredPractitioner = [];
        for (let i: number = 0; i < this.pages.length; i++) {
          let page: Practitioners[] = this.pages[i];
          for (let j: number = 0; j < page.length; j++) {

            let practitioner: Practitioners = page[j];
            if (this.countryForm.value.city.toLowerCase() == practitioner.city.toLowerCase() || (practitioner.city2 !== null && this.countryForm.value.city.toLowerCase() == practitioner.city2.toLowerCase())) {
              filteredPractitioner.push(practitioner);
              if (filteredPractitioner.length === this.pageLimit || page.length !== this.pageLimit && page.length - 1 === j) {
                filteredPages.push(filteredPractitioner);
                filteredPractitioner = [];
              }
            } else {
              if ((page.length === this.pageLimit && filteredPractitioner.length > 0 && page.length - 1 === j && i === this.pages.length - 1) || (page.length !== this.pageLimit && page.length - 1 === j && i === this.pages.length - 1)) {
                filteredPages.push(filteredPractitioner);
                filteredPractitioner = [];
              } 
            }
          }

        }
        this.pages = filteredPages;
        this.totalPages = this.pages.length;
        this.getTotalPractitioners();
      } else {
        this.createPractitioners();
      }
      this.countryForm.get('country').setValue('all');
    } else {
      if (this.countryForm.value.country.toLowerCase() !== 'all') {
        let filteredPages: Practitioners[][] = [];
        let filteredPractitioner = [];
        for (let i: number = 0; i < this.pages.length; i++) {
          let page: Practitioners[] = this.pages[i];
          for (let j: number = 0; j < page.length; j++) {

            let practitioner: Practitioners = page[j];
            if (this.countryForm.value.country.toLowerCase() == practitioner.country.toLowerCase()) {
              filteredPractitioner.push(practitioner);
              if (filteredPractitioner.length === this.pageLimit || page.length !== this.pageLimit && page.length - 1 === j) {

                filteredPages.push(filteredPractitioner);
                filteredPractitioner = [];
              }
            } else {
              if (filteredPractitioner.length > 0 && page.length !== this.pageLimit || (page.length - 1 === j && i === this.pages.length - 1)) {
                filteredPages.push(filteredPractitioner);
                filteredPractitioner = [];
              }
            }
          }

        }
        this.pages = filteredPages;
        this.totalPages = this.pages.length;
        this.getTotalPractitioners();
      } else {
        this.createPractitioners();
      }
      this.countryForm.get('city').setValue('all');
    }
    this.activePage = 0;
    this.currentPage = 0;
    this.isFormSubmited = true;
  }

  getPractitionersResponse() {
    this.practitionersServices.getPractitioner().subscribe(responseData => {
      responseData.sort((a: Practitioners, b: Practitioners) => a.name.localeCompare(b.name));
      this.practitioners = responseData;
      this.totalPages = this.pages.length;
      this.createPractitioners();
      this.getPlaces();
    });
  }

  createPractitioners() {
    this.pages = [];
    let page: Practitioners[] = [];
    let pageCount: number = 1;
    let visibleCount: number = -1;
    this.practitioners.forEach((item: Practitioners, i: number) => {
      if (item.visible == 1) {
        visibleCount++;
        page.push(item);
        if (this.init && item.name) {
          let chars = item.name.split("");
          let startCount: number = 0

          for (const key2 in chars) {
            if (chars[key2] == "*") {
              startCount++;
            }
          }

          if (item.name.indexOf("*")) {
            const nameReplaced = item.name.replace(new RegExp('\\*', 'g'), '');
            item.name = nameReplaced;
          }

          this.stars.set(item.name, startCount);
        }
      }
      if (visibleCount === this.pageLimit * pageCount - 1 || i === this.practitioners.length - 1) {
        this.pages.push(page);
        page = [];
        pageCount++;
      }
    });
    visibleCount = -1;
    this.totalPages = this.pages.length;
    this.getTotalPractitioners();
    this.init = false;
  }

  onSeeAll() {
    this.createPractitioners();
  }

  getPlaces() {
    // this.placesServices.getPlace().subscribe(responseData => { 
    //   responseData.forEach((item: Practitioners, i: number) =>{
    //     if(this.countries.indexOf(item.country) === -1 && item.country !== 'null'){
    //       this.countries.push(item.country);
    //     }
    //     if(this.cities.indexOf(item.city) === -1 && item.city !== 'null'){
    //       this.cities.push(item.city);
    //     }
    //   })
    // });
    for (let i = 0; i < this.pages.length; i++) {
      const page: Practitioners[] = this.pages[i];
      for (let j = 0; j < page.length; j++) {
        const item = page[j];
        if (this.countries.indexOf(item.country) === -1 && item.country !== 'null' && item.country !== null) {
          this.countries.push(item.country);
        }
        if (this.cities.indexOf(item.city) === -1 && item.city !== 'null' && item.city !== null) {
          this.cities.push(item.city);
        }
        if (this.cities.indexOf(item.city2) === -1 && item.city2 !== 'null' && item.city2 !== null) {
          this.cities.push(item.city2);
        }
      }
    }
  }

  getTotalPractitioners() {
    this.totalPractitioners = 0;
    for (let i = 0; i < this.pages.length; i++) {
      const pagesLength: number = this.pages[i].length;
      this.totalPractitioners += pagesLength;
    }
  }
}
