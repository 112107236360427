<!--The content below is only a placeholder and can be replaced.-->
<body>
    <div class="container-fluid">
        <app-header></app-header>
        <div class="main"><router-outlet></router-outlet></div>

       <div class="footer"><app-footer></app-footer></div>
    </div>
</body>

