import { ServerUrlServices } from './../../../shared/server-url.services';
import { Instructors } from './../instructors/instructors.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class InstructorsServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
  getTherapist(): Observable<any> {
    return this.http.get<{ [key: string]: Instructors }>(this.serverUrlServices.serverApi + 'therapists')
      .pipe(map(responseData => {
        const postsArray: Instructors[] = [];
        for (const key in responseData.therapists) {
          if (responseData.therapists.hasOwnProperty(key)) {
            if(responseData.therapists[key].image_url){
              const image_url = responseData.therapists[key].image_url;
              if(image_url.indexOf(this.serverUrlServices.serverStorage) == -1){
                 responseData.therapists[key].image_url = this.serverUrlServices.serverStorage + image_url;
              }
            }
            postsArray.push({ ...responseData.therapists[key], key });
          }
        }
       // console.log("[mj]-getTherapists", responseData);
        return postsArray;
      })
      )
  }


  postTherapist( postData: Instructors) {


    const fd = new FormData();
    fd.append('image', postData.image);
    fd.append('image_url', postData.image.name);
    fd.append('name', postData.name);
    fd.append('content', postData.content);
  /*  console.log("postData", fd);
    console.log("postData2", postData.image.name);*/
    return this.http.post(this.serverUrlServices.serverApi + 'therapist', fd);
  }


  getEditInstructor( postData: Instructors) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   /* const fd = new FormData();
    fd.append('image', postData.image);
    fd.append('image_url', postData.image.name);
    fd.append('name', postData.name);
    fd.append('content', postData.content);
    console.log('[mj]-getEditInstructor', fd.has('image'));*/
    return this.http.put(this.serverUrlServices.serverApi + 'therapist' + '/' + postData.id, postData, { headers});
  }

  deleteInstructor(id: number) {
    return this.http.delete<{ [id: number]: Instructors }>(this.serverUrlServices.serverApi + 'therapist' + '/' + id);
  }

}
