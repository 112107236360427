import { Instructors } from './../../seminars/instructors/instructors.interface';
import { InstructorsServices } from './../../seminars/instructors/instructors.services';
import { Seminars } from './../../seminars/upcoming-seminars/seminars.interface';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SeminarsServices } from '../../seminars/upcoming-seminars/seminars.service';
import { timer } from 'rxjs';
import { EventsServices } from '../../news/events/events.service';
import { Events } from '../../news/events/events.interface';
import { Video } from '../../news/videos/video.interface';
import { VideoServices } from '../../news/videos/video.service';
import { PlacesServices } from 'src/app/shared/places/places.services';
import { Places } from 'src/app/shared/places/places.interface';
import { Practitioners } from '../../practitioners/practitioners.interface';
import { PractitionersServices } from '../../practitioners/practitioners.services';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
 
  seminars: Seminars[] = [];
  instructors: Instructors[] = [];
  events: Events[] = [];
  videos: Video[] = [];
  practitioners: Practitioners[] = [];
  places: Places[] = [];
  cities: string[] = [];
  countries: string[] = [];
  isLoggedIn: boolean = false;
  isFormShown: boolean = false;
  isInstructorsFormShown: boolean = false;
  isEventsFormShown: boolean = false;
  isVideosFormShown: boolean = false;
  isPlacesFormShown: boolean = false;
  isFormSubmitSuccess: boolean = false;
  isSeminarFormSubmited: boolean = false;
  isEventsFormSubmited: boolean = false;
  isVideosFormSubmited: boolean = false;
  isPractitionersFormShown: boolean = false;
  isPractitionersFormSubmited: boolean = false;
  isPlacesFormSubmited: boolean = false;
  editValue: string;
  seminarsForm: FormGroup;
  instructorsForm: FormGroup;
  eventsForm: FormGroup;
  videosForm: FormGroup;
  practitionersForm: FormGroup;
  placesForm: FormGroup;
  editValues: Practitioners;
  //quotes: AboutUs[] = [];
  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, private route: Router, private seminarsServices: SeminarsServices,
     private instructorsServices: InstructorsServices, private eventsServices: EventsServices, private videoServices: VideoServices,
     private practitionersServices: PractitionersServices, private placesServices: PlacesServices) { }

  ngOnInit() {
    this.seminarsForm = new FormGroup({
      // 'id': new FormControl(null, Validators.required),
      'town': new FormControl(null, Validators.required),
      'seminars': new FormControl(null, Validators.required),
      'group': new FormControl(null, Validators.required),
      'instructors': new FormControl(null, Validators.required),
      'date': new FormControl(null, Validators.required),
      'time': new FormControl(null, Validators.required),
      'title': new FormControl(null, Validators.required)
    });

    this.instructorsForm = new FormGroup({
      // 'id': new FormControl(null, Validators.required),
      'image': new FormControl(null, Validators.required),
      'name': new FormControl(null, Validators.required),
      'content': new FormControl(null, Validators.required)
    });

    this.eventsForm = new FormGroup({
      // 'id': new FormControl(null, Validators.required),
      'image': new FormControl(null, Validators.required),
      'title': new FormControl(null, Validators.required),
      'place': new FormControl(null, Validators.required),
      'detail': new FormControl(null, Validators.required),
      'short_detail': new FormControl(null, Validators.required),
      'date': new FormControl(null, Validators.required),
      'time': new FormControl(null, Validators.required)
    });

    this.videosForm = new FormGroup({
      // 'id': new FormControl(null, Validators.required),
      'video_url': new FormControl(null, Validators.required)
    });

    this.practitionersForm = new FormGroup({
      // 'id': new FormControl(null, Validators.required),
      'name': new FormControl(null, Validators.required),
      'phone': new FormControl(null, Validators.required),
      'email': new FormControl(null, Validators.required),
      'country': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
      'city2': new FormControl(null),
      'visible': new FormControl(null),
    });

    this.placesForm = new FormGroup({
      'country': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
    });

    if (this.authService.loggedIn) {
      this.isLoggedIn = true;
      //console.log("[mj]-AboutUsLoggedIN", this.authService.loggedIn);
    } else {
      this.isLoggedIn = false;
      //console.log("[mj]-AboutUsLoggedIN", this.authService.loggedIn);
    }

    this.onGetSeminars();
    this.onGetTherapist();
    this.onGetEvents();
    this.onGetVideos();
    this.onGetPractitioners();
    this.onGetPlaces();
  }

  onSubmit() {
    this.seminarsServices.postContents(this.seminarsForm.value).subscribe(
      responseData => {
        this.onGetSeminars();
        this.isSeminarFormSubmited = true;
        if(this.isSeminarFormSubmited){
          this.resetSubmitedPopup();
        }

      }
    );
    this.seminarsForm.reset();
  }

  onLogout() {

    this.authService.logout();
    this.route.navigate(['bowen/login']);

  }
//Seminars
  onGetSeminars() {
    this.seminarsServices.getContents().subscribe(responseData => {
      this.seminars = responseData;
    });
  }

  onDeleted(seminar: Seminars) {
    const position = this.seminars.findIndex((seminarEl: Seminars) => {
      return seminarEl.id == seminar.id;
    });
    this.seminars.splice(position, 1);
  }

  onShowForm(): void {
    this.isFormShown = true;

  }

  onHideForm(): void {
    this.isFormShown = false;
  }
//Instructors
  onFileSelected(event) {

    this.instructorsForm.value.image = event.target.files[0];

  }
  onSubmitTherapist(): void {
    this.instructorsServices.postTherapist(this.instructorsForm.value).subscribe(responseData => {
      if(responseData){
        this.isFormSubmitSuccess = true;
        if(this.isFormSubmitSuccess){
          this.resetSubmitedPopup();
        }

      }
      this.onGetTherapist();
    });
    this.instructorsForm.reset();
  }

  onGetTherapist() {
    this.instructorsServices.getTherapist().subscribe(responseData => {
      this.instructors = responseData;

    });
  }

  onDeletedInstructor(instructor: Instructors) {
    const position = this.instructors.findIndex((instructorEl: Instructors) => {
      return instructorEl.id == instructor.id;
    });
    this.instructors.splice(position, 1);
  }

  onShowInstructorsForm(): void {
    this.isInstructorsFormShown = true;

  }
  onHideInstructorsForm(): void {
    this.isInstructorsFormShown = false;

  }
  resetSubmitedPopup(): void {
    setTimeout(() => {
      this.isFormSubmitSuccess = false;
      this.isSeminarFormSubmited = false;
      this.isEventsFormSubmited = false;
      this.isVideosFormSubmited = false;
      this.isPractitionersFormSubmited = false;
      this.isPlacesFormSubmited = false;
    }, 3000);
  }

//Events

  onGetEvents():void {
    this.eventsServices.getEvent().subscribe(responseData => {
      this.events = responseData;
    });
  }

  onDeletedEvent(event: Events): void {
    const position = this.events.findIndex((eventEl: Events) => {
      return eventEl.id == event.id;
    });
    this.events.splice(position, 1);
  }
  onFileEventSelected(event) {

    this.eventsForm.value.image = event.target.files[0];

  }

  onShowEventsForm(): void {
    this.isEventsFormShown = true;

  }
  onHideEventsForm(): void {
    this.isEventsFormShown = false;

  }

  onSubmitEvents(): void {
    this.eventsServices.postEvent(this.eventsForm.value).subscribe(responseData => {
      if(responseData){
        this.isEventsFormSubmited = true;
        if(this.isEventsFormSubmited){
          this.resetSubmitedPopup();
        }

      }
      this.onGetEvents();
    });
    this.eventsForm.reset();
  }

  //Videos

  onGetVideos():void {
    this.videoServices.getVideo().subscribe(responseData => {
      this.videos = responseData;
    });
  }



  onDeletedVideo(video: Video): void {
    const position = this.videos.findIndex((videoEl: Video) => {
      return videoEl.id == video.id;
    });
    this.videos.splice(position, 1);
  }

  onShowVideosForm(): void {
    this.isVideosFormShown = true;

  }
  onHideVideosForm(): void {
    this.isVideosFormShown = false;

  }

  onSubmitVideos(): void {
    this.videoServices.postVideo(this.videosForm.value).subscribe(responseData => {
      if(responseData){
        this.isVideosFormSubmited = true;
        if(this.isVideosFormSubmited){
          this.resetSubmitedPopup();
        }

      }
     this.onGetVideos();
    });
    this.videosForm.reset();
  }


  // Practitioners
  onSubmitPractitioner(): void {
    console.log("[mj]-ControlPractitioners",this.practitionersForm.value);
    this.practitionersServices.postPractitioner(this.practitionersForm.value).subscribe(responseData => {
      if(responseData){
        this.isPractitionersFormSubmited = true;
        if(this.isPractitionersFormSubmited){
          this.resetSubmitedPopup();
        }

      }
      this.onGetPractitioners();
    });
    this.practitionersForm.reset();
  }

  onGetPractitioners() {
    this.practitionersServices.getPractitioner().subscribe(responseData => {
      this.practitioners = responseData;

    });
  }

  onDeletedPractitioner(practitioners: Practitioners) {
    const position = this.practitioners.findIndex((practitionersEl: Practitioners) => {
      return practitionersEl.id == practitioners.id;
    });
    this.practitioners.splice(position, 1);
  }

  onShowPractitionersForm(): void {
    this.isPractitionersFormShown = true;

  }
  onHidePractitionersForm(): void {
    this.isPractitionersFormShown = false;

  }

// Places

onSubmitPlaces(): void {

  //proveri sutra zbog cega obrise pa doda mesto, ovde je problem :)
  console.log("[mj]-ControlPractitioners",this.placesForm.value);
  this.placesServices.postPlace(this.placesForm.value).subscribe(responseData => {
    console.log("placesResponseData333", responseData);
    if(responseData){
      this.isPlacesFormSubmited = true;
      if(this.isPlacesFormSubmited){
        this.resetSubmitedPopup();
      }

    }
    this.onGetPlaces();
    console.log("[mj]-ControlPractitioners22222",this.places);
  });
  this.placesForm.reset();
}

onGetPlaces() {
  this.placesServices.getPlace().subscribe(responseData=> {
    console.log("[mj]*-PlacesServicesOnGet",responseData);
    const blank :string = " "; 
    this.cities = [blank];
    this.countries = [blank];
    this.places = responseData;
    for (const key in responseData) {
      
      if(this.cities.indexOf(responseData[key].city) === -1){
        this.cities.push(responseData[key].city);
      }
      if(this.countries.indexOf(responseData[key].country) === -1){
        this.countries.push(responseData[key].country);
      }
      
    }
  });
}
  onShowPlacesForm(): void {
    this.isPlacesFormShown = true;

  }
  onHidePlacesForm(): void {
    this.isPlacesFormShown = false;

  }
  onDeletedPlace(places: Places) {
    const position = this.places.findIndex((placesEl: Places) => {
      return placesEl.id == places.id;
    });
    this.places.splice(position, 1);
  }

}
