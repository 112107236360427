<div>
    <img src="assets/rejuvance-logo.png" alt="..." class="logo">

    <br>
</div>

<div class="card ">
    <div class="row" (click)="isVisibleSection1 = !isVisibleSection1">
      <div class="col-lg-10 col-md-10 col-sm-10 col-10">
        <p>Šta je Režuvans?</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
        <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection1"></i><i
            class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection1"></i></span>

      </div>
    </div>
  </div>
  <div class="container card-body" *ngIf="isVisibleSection1" >
<p>Režuvans je posebna tehnika nežne masaže lica sa efektom podmlađivanja. Sastoji se od delikatnih klizećih pokreta po površini lica, kojima se postiže prirodan efekat liftinga.
<br>
<br>
    Filozofija koja stoji iza Režuvans masaže je da naše lice odražava povezanost tela, uma, duha i emocija. Lice je ono što prvo vidimo kod druge osobe, ono je otkriveno, izloženo i odražava, ali i skriva istoriju svake osobe. Izraz lica je oblikovan mnoštvom sitnih mišića, takozvanih „mimičnih mišića”, koji licu daju potrebnu pokretljivost za njegovu izražajnost i stvaranje mimike (grimasa). Stres i napetost ogledaju se u zatezanju obrva, vilice i očiju, dok radost i spokoj daju licu otvoren i opušten izraz.
    <br><br>
    Ova nežna masaža lica daje nam osećaj duboke opuštenosti i „povezanosti“ sa svim delovima tela, kao i osećaj uživanja u životu. Kada se napetost ukloni, rezultati su iznenađujući.</p>
  </div>

  <div class="card">
      <div class="row" (click)="isVisibleSection2 = !isVisibleSection2">
        <div class="col-lg-10 col-md-10 col-sm-10 col-10">
          <p>Kako Režuvans deluje?</p>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
          <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection2"></i><i
              class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection2"></i></span>

        </div>
      </div>
    </div>
    <div class="container card-body" *ngIf="isVisibleSection2" >
        Režuvans tehnika je manuelan, potpuno prirodan metod tretmana lica (bez upotrebe krema, seruma ili elektrostimulacija), koji se bazira na fiziološkim procesima tela i podstiče strukture lica da povrate svoju vitalnost.
<br><br>
        Režuvans pokreti su nežni, postepeno deluju na sva meka tkiva lica, i duboka i površinska, uspostavlja se ponovo normalan protok hranljivih materija, napetosti popuštaju i poboljšava se elastičnost kože i tonus mišića.
        <br><br>
        Tretmanom se uklanjaju napetost i zatezanja u licu, vratu, ramenima, a istovremeno se mišićima vraća tonus i elastičnost. Poboljšava se kvalitet kože i njena hidriranost, smanjuju se pore, eliminišu se toksini kroz stimulaciju limfnog sistema, tamni kolutovi i otoci oko očiju se značajno smanjuju, fine bore nestaju dok se dublje ublažavaju, konture lica su bolje defenisane.
        <br><br>
        To je nežan ali efektan dodir, koji obnavlja i osvežava lice, dovodeći u isto vreme do relaksacije celog tela i osećaja blagostanja.
    </div>

    <div class="card">
        <div class="row" (click)="isVisibleSection3 = !isVisibleSection3">
          <div class="col-lg-10 col-md-10 col-sm-10 col-10">
            <p>Režuvans tretmani</p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
            <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection3"></i><i
                class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection3"></i></span>

          </div>
        </div>
      </div>
      <div class="container card-body" *ngIf="isVisibleSection3" >
         <p> Preporučuje se serija od šest tretmana, na svaka dva do tri dana, ali rezultati će se videti već posle prvog tretmana: površne bore će nestati, a one dublje će se ublažiti, mišićima lica će se vratiti tonus, koža će izgledati svetlija i svežija.

          <br><br>
          U svim tretmanima, jedan za drugim, tretiraće se mišići i vezivno tkivo, kao i druge strukture koje učestvuju u stvaranju izraza lica, kako one na licu, tako i na vratu, dekolteu, glavi i poboljšanje će se stalno i postepeno uvećavati.
<br><br>
          Preporučeni tretmani održavanja (jednom mesečno ili jednom u dva meseca), neće samo sačuvati postignute rezultate, već će ih i poboljšati.
          <br><br>
          Pored estetkog efekta, Režuvans tehnika ima efekat duboke relaksacije na celo telo, zato što se na licu i na glavi nalaze počeci (ili krajevi) svih energetskih meridijana. Posle tretmana koji traje najviše sat vremena, osećaćemo se i izgledati kao da smo se odmarali cele noći.
         </p></div>
         <div class="info-div">
          <div class="col-md-12">
              <img src="assets/logoRejPng.png" class="info-logo" >
              <h1>ZAKAŽITE TRETMAN</h1>

            <p>Ukoliko vas interesuje neki od tretmana, svoj termin možete zakazati putem telefona:</p>
            <h2>060/014-99-49</h2>

          </div>

        </div>
