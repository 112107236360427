import { ServerUrlServices } from './../../shared/server-url.services';
import { Seminars } from './../seminars/upcoming-seminars/seminars.interface';
import { Component, OnInit } from '@angular/core';
import { SeminarsServices } from '../seminars/upcoming-seminars/seminars.service';

@Component({
  selector: 'app-rejuvance',
  templateUrl: './rejuvance.component.html',
  styleUrls: ['./rejuvance.component.css']
})
export class RejuvanceComponent implements OnInit {

  serverUrl: string = '';

  isVisibleSection1: Boolean = false;
  isVisibleSection2: Boolean = false;
  isVisibleSection3: Boolean = false;
  constructor() { }

  ngOnInit() {

  }
}
