import { RejInstructorsComponent } from './pages/rejuvance-seminars/rej-instructors/rej-instructors.component';
import { RejUpcomingSeminarsComponent } from './pages/rejuvance-seminars/rej-upcoming-seminars/rej-upcoming-seminars.component';
import { RejTrainingComponent } from './pages/rejuvance-seminars/rej-training/rej-training.component';
import { RejuvanceSeminarsComponent } from './pages/rejuvance-seminars/rejuvance-seminars.component';
import { PhotosComponent } from './pages/news/photos/photos.component';
import { TeachingProgramComponent } from './pages/seminars/teaching-program/teaching-program.component';
import { TrainingCourseComponent } from './pages/seminars/training-course/training-course.component';
import { BtaaComponent } from './pages/seminars/btaa/btaa.component';
import { RejuvanceComponent } from './pages/rejuvance/rejuvance.component';

import { VideoComponent } from './pages/news/videos/video.component';
import { PublicationsComponent } from './pages/news/publications/publications.component';
import { EventsComponent } from './pages/news/events/events.component';
import { InstructorsComponent } from './pages/seminars/instructors/instructors.component';
import { UpcomingSeminarsComponent } from './pages/seminars/upcoming-seminars/upcoming-seminars.component';
import { AdminComponent } from './pages/login/admin/admin.component';
import { LoginComponent } from './pages/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NewsComponent } from './pages/news/news.component';
import { IndexComponent } from './pages/index/index.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BowenTechniqueComponent } from './pages/bowen-technique/bowen-technique.component';
import { SeminarsComponent } from './pages/seminars/seminars.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AuthGuard } from './pages/login/auth-guard.service';
import { AboutStankaComponent } from './pages/about-stanka/about-stanka.component';
import { EventDetailComponent } from './pages/news/event-detail/event-detail.component';
import { PractitionersComponent } from './pages/practitioners/practitioners.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'index' , pathMatch: 'full'},
  { path: 'index', component: IndexComponent },
  { path: 'seminari', component: SeminarsComponent,  children: [
    { path: 'btaa', component: BtaaComponent },
    { path: 'obuka', component: TrainingCourseComponent },
    { path: 'nastavni-program', component: TeachingProgramComponent },
    { path: 'predstojeci-seminari', component: UpcomingSeminarsComponent },
    { path: 'instruktori', component: InstructorsComponent },

  ]},
  { path: 'bowen-tehnika', component: BowenTechniqueComponent },
  { path: 'prakticari', component: PractitionersComponent },
  { path: 'novosti', component: NewsComponent, children: [
    { path: 'dogadjaji', component: EventsComponent },
    { path: 'detalji-dogadjaja/:id', component: EventDetailComponent},
    { path: 'publikacije', component: PublicationsComponent },
    { path: 'video', component: VideoComponent },
    { path: 'galerija', component: PhotosComponent }
  ]},
  //{ path: 'holisticki-centar', component: HolisticCenterComponent},
  { path: 'onama', component: AboutUsComponent},
  { path: 'bowen/login',  component: LoginComponent},
  { path: 'kontakt', component: ContactComponent},
  {path: 'bowen/login/admin', canActivate: [AuthGuard], component: AdminComponent},
  { path: 'rejuvance', component: RejuvanceComponent },
  { path: 'stanka', component: AboutStankaComponent },
  { path: 'rejuvance-seminari', component: RejuvanceSeminarsComponent,  children: [
    { path: 'obuka', component: RejTrainingComponent  },
    { path: 'predstojeci-seminari', component: RejUpcomingSeminarsComponent },
    { path: 'instruktori', component: RejInstructorsComponent }

  ]}

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
