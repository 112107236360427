import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventsServices } from 'src/app/pages/news/events/events.service';
import { Events } from 'src/app/pages/news/events/events.interface';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

 

  @Input() event: Events;
  @Output() eventDeleted: EventEmitter<Events> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValues: Events ;
  constructor(private eventsServices: EventsServices) { }

  ngOnInit() {
  }
  ngOnChanges() {
    console.log("jelkaOnChanges");
  }
  onEdit(event: Events) {
    this.isEdit = true;
    this.editValues = event;
  }
  onCancel() {
   // this.editValues = {};
    this.isEdit = false;
  }
  onFileSelected(e) {

    this.editValues.image = e.target.files[0];
  }

  onSave(id: number) {
    //this.event = this.editValues;
    console.log("event", this.event);
    this.eventsServices.getEditEvent(this.event).subscribe((responseData: Event) => {
      this.event = this.editValues;
    this.editValues = {
      id: null,
      image: null,
      image_url: '',
      title: '',
      place: '',
      detail: '',
      short_detail: '',
      date: '',
      time: ''
      }
    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.eventsServices.deleteEvent(id).subscribe(
      responseData => {
        this.eventDeleted.emit(this.event);
        this.isDeleted = true;
      }
    )
  }

}
