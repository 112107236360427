import { Instructors } from './instructors.interface';
import { InstructorsServices } from './instructors.services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.css']
})
export class InstructorsComponent implements OnInit {
  // @Input() event: Events;
  // @Output() eventDeleted: EventEmitter<Events> = new EventEmitter();
  instructors:Instructors[] = [];
  stars = new Map<string, number>();
  constructor(private instructorsServices: InstructorsServices) { }

  ngOnInit() {
    this.onGetTherapist();
  }

  onGetTherapist() {
    this.instructorsServices.getTherapist().subscribe(responseData => {

      responseData.forEach((item: any, i: number) =>{
        let name = item.name.split("*");
        if(name[0] === "Stanka Stojkov"){
          responseData.splice(i, 1);
          responseData.unshift(item);
        }

        if (item.name) {
          let chars = item.name.split("");
          let startCount: number = 0

          for (const key2 in chars) {
            if (chars[key2] == "*") {
              startCount++;
            }
          }
          
          if (item.name.indexOf("*")) {
            const nameReplaced = item.name.replace(new RegExp('\\*', 'g'), '');
            item.name = nameReplaced;
          }
          
          this.stars.set(item.name,startCount);
        }
      });
      
    this.instructors = responseData;
    });
    
  }
  arrayStar(n: number): any[] {
    return Array(n);
  }

}
