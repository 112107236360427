import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  siteUrl = "https://www.facebook.com/profile.php?id=61552261982148";

  constructor() { }

  ngOnInit() {
  }
  openSiteinNewTab() {
    window.open(this.siteUrl, '_blank');
  }
}
