import { AboutUs } from './../about-us/about-us.interface';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { AboutUsServices } from '../about-us/about-us.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit, OnChanges {

  @Input() quote: AboutUs;
  @Output() quoteDeleted: EventEmitter<AboutUs> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValue: string;
  constructor(private aboutUsServices: AboutUsServices) { }

  ngOnInit() {
    console.log("quotes", this.quote);
  }

  ngOnChanges() {
    
  }
  onEdit(quote: AboutUs) {
    this.isEdit = true;
    this.editValue = quote.content;

  }
  onCancel() {
    this.editValue = '';
    this.isEdit = false;
  }

  onSave(id: number) {
    this.quote.content = this.editValue;
    console.log("quote", this.quote);
    this.aboutUsServices.getEditContent(this.quote).subscribe((responseData: AboutUs) => {
     this.quote.content = this.editValue;
      //this.quote = responseData ;
      console.log("editContnet", responseData);
      this.editValue = '';

    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.aboutUsServices.deleteContent(id).subscribe(
      responseData => {

        this.quoteDeleted.emit(this.quote);
        //return message if quote deleted!
        this.isDeleted = true;
      }
    );


  }

}
