import { ServerUrlServices } from './../../../shared/server-url.services';
import { SeminarsServices } from './../../seminars/upcoming-seminars/seminars.service';
import { Seminars } from './../../seminars/upcoming-seminars/seminars.interface';

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rej-upcoming-seminars',
  templateUrl: './rej-upcoming-seminars.component.html',
  styleUrls: ['./rej-upcoming-seminars.component.css']
})
export class RejUpcomingSeminarsComponent implements OnInit {

  seminars: Seminars[] = [];
  titles: string[] = [];
  constructor(private seminarsServices: SeminarsServices, private serverUrlServices: ServerUrlServices) { }

  ngOnInit() {
    this.seminarsServices.getContents().subscribe(responseData => {
      this.seminars = responseData;
      // tslint:disable-next-line: forin
      for (const key in responseData) {
        if (!this.titles.includes(responseData[key].title)) {
          this.titles.push(responseData[key].title);
        }
        if (responseData[key].date.indexOf(',') > -1 || responseData[key].time.indexOf(',')) {
          const item = responseData[key].date.replace(new RegExp(',', 'g'), '\n');
          const item2 = responseData[key].time.replace(new RegExp(',', 'g'), '\n');
          responseData[key].date = item;
          responseData[key].time = item2;
          /*console.log(['mj-Date', responseData[key].date]);
          console.log(['mj-Time', responseData[key].time]);*/
        }
      }
    });
  }
}
