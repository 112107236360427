import { ServerUrlServices } from './../../../shared/server-url.services';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Events, EventsMail } from './events.interface';

@Injectable()
export class EventsServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
  getEvent(): Observable<any> {
    return this.http.get<{ [key: string]: Events }>(this.serverUrlServices.serverApi + 'events')
      .pipe(map(responseData => {
        const postsArray: Events[] = [];
        for (const key in responseData.events) {
          if (responseData.events.hasOwnProperty(key)) {
            if(responseData.events[key].image_url){
              const image_url = responseData.events[key].image_url;
              if(image_url.indexOf(this.serverUrlServices.serverStorage) == -1){
                const new_image_url = this.serverUrlServices.serverStorage + image_url;
                responseData.events[key].image_url = new_image_url;
              }
            }
            postsArray.push({ ...responseData.events[key], key });
          }
        }
        return postsArray;
      })
      )
  }


  postEvent( postData: Events) {

    console.log("postData3", postData.image.name);
    const fd = new FormData();
    fd.append('image', postData.image);
    fd.append('image_url', postData.image.name);
    fd.append('title', postData.title);
    fd.append('place', postData.place);
    fd.append('detail', postData.detail);
    fd.append('short_detail', postData.short_detail);
    fd.append('date', postData.date);
    fd.append('time', postData.time);
    return this.http.post(this.serverUrlServices.serverApi + 'event', fd);
  }


  getEditEvent( postData: Events) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   /* const fd = new FormData();
    fd.append('image', postData.image);
    fd.append('image_url', postData.image.name);
    fd.append('name', postData.name);
    fd.append('content', postData.content);
    console.log('[mj]-getEditInstructor', fd.has('image'));*/
    return this.http.put(this.serverUrlServices.serverApi + 'event' + '/' + postData.id, postData, { headers});
  }

  deleteEvent(id: number) {
    return this.http.delete<{ [id: number]: Events }>(this.serverUrlServices.serverApi + 'event' + '/' + id);
  }


  postEmail( postData: EventsMail) {
   // console.log("postEmailData", postData);
    return this.http.post<{ [key: string]: EventsMail }>(this.serverUrlServices.serverApi + 'eventMail', postData);
  }
}
