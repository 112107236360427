import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServerUrlServices } from '../server-url.services';
import { Places } from './places.interface';

@Injectable()
export class PlacesServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
  getPlace(): Observable<any> {
    return this.http.get<{ [key: string]: Places }>(this.serverUrlServices.serverApi + 'places')
      .pipe(map(responseData => {
        const postsArray: Places[] = [];
        for (const key in responseData.places) {
          if (responseData.places.hasOwnProperty(key)) {
            postsArray.push({ ...responseData.places[key], key });
          }
        }
       console.log("[mj]-getPlaces", responseData);
        return postsArray;
      })
      )
  }

  postPlace( postData: Places) {
    console.log("[mj]-postDataPlace1111", postData);

    const fd = new FormData();
    fd.append('country', postData.country);
    fd.append('city', postData.city);
    return this.http.post(this.serverUrlServices.serverApi + 'place', fd);
  }


  getEditPlace( postData: Places) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    console.log("[mj]-Edit",postData)
    return this.http.put(this.serverUrlServices.serverApi + 'place' + '/' + postData.id, postData, { headers});
  }

  deletePlace(id: number) {
    console.log("placesResponseData2222", id);
    return this.http.delete<{ [id: number]: Places }>(this.serverUrlServices.serverApi + 'place' + '/' + id);
  }

}
