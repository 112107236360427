<br>
<div class="container form-container ">
  <form class="card" [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
    <div class="form-body">
      <div class="form-group">
        <div class="row">

          <div class="col-lg-1">
            <i class="fas fa-envelope fa-2x"></i>
          </div>
          <div class="col-lg-11">
            <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email">
          </div>
        </div>
      </div>
       <div class="form-group">
          <div class="row">

            <div class="col-lg-1">
              <i class="fas fa-key fa-2x"></i>
            </div>
            <div class="col-lg-11">
              <input type="password" class="form-control" formControlName="password" id="title" placeholder="Password">
            </div>
          </div>
        </div>
      <div class="submit-button">
        <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid" >Login</button>
      </div>

    </div>
  </form>
</div>
