<span class="logo" >
  <img src="assets/infoLogo.png">
</span>
<h1>Bowen tehnika</h1>
<div class="card">
    <div class="row" (click)="isVisibleSection1 = !isVisibleSection1">
      <div class="col-lg-10 col-md-10 col-sm-10 col-10">
        <p>Istorijat.</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
        <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection1"></i><i
            class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection1"></i></span>

      </div>
    </div>
  </div>
  <div class="container card-body" *ngIf="isVisibleSection1" >

      <img src="assets/tom-bowen.jpg" alt="..." class="img-thumbnail rounded-circle">
      <h2>Bowen tehnika je dobila ime po svom osnivaču Tom Bowen-u, koji je počeo da je razvija pedesetih godina prošlog veka u Australiji.</h2>

      <p>Rođen je u Australiji, u Brunsviku, predgradju Melburna. Bio je jedini sin, mada je porodica Bowen, koja je iz Britanije emigrirala u Australiju početkom dvadesetog veka, imala i dve ćerke, Viliamu i Noru, starije od Toma. Tom je želeo da uči medicinu, ali zbog slabog materijalnog stanja porodice nije imao tu mogućnost, već je kao i njegov otac postao stolar i zaposlio se u lokalnoj fabrici cementa.</p>
      <h2>Iako bez formalnog medicinskog znanja, želja da pomogne drugima ga je vodila kroz život.</h2>
   <p>
    Učio kako da izvodi određene pokrete i tehnike od nadarenih praktičara, svojih savremenika, tokom 1920-ih i 1930-ih godina. Proučavao je metode komplementarne medicine i intuitivno primenjivao i integrisao ono što je smatrao da je najefikasnije. Pokreti koje je savladao i oni koje je sam izumeo, kasnije su pretočeni u ono što će postati Bowen Terapijska Tehnika.
        <br>
        <br>
        Njegova supruga Džesi je imala astmu i zbog gušenja je često bila hospitalizovana. U želji da joj pomogne, Tom je počeo da primenjuje svoje znanje i Džesino zdravstveno stanje se popravljalo, te posle nekog vremena nije bilo potrebe za lekovima ili hospitalizacijom zahvaljujući njegovom metodu.
        <br>
        <br>
        Takođe je pomagao i svojim kolegama na poslu. Svi su ubrzo shvatili da Tom ima svoj jedinstveni metod za pomoć svakom ko se povredi. Danju je još uvek radio kao stolar, dok je noću, kod kuće, lečio sve više ljudi koji su tražili pomoć za različite zdravstvene probleme.
        <br>
        <br>
        Njegovi prijatelji Sten i Rene Horvud ubrzo su mu ponudili deo svoje kuće za ordinaciju, a Rene je prihvatila da mu bude sekretarica i vodi računa o svim praktičnim pitanjima.
        <br>
        <br>
        To je bio početak životnog puta čoveka koji je svojim nesebičnim radom i energijom postao vrlo poštovan i cenjen.
   <br>
   <br>
   Ubrzo je imao toliko klijenata, da je postalo očigledno da ne može ostati u privatnoj kući, pa se preselio na drugu lokaciju, a kasnije se opet preselio, pa je imao čitavu kliniku gde je obavljao oko 13 000 tretmana godišnje, što je čak potvrđeno 1975. godine od strane Ministarstva zdravlja.
   <br>
   <br>
   U radu ga je prvenstveno vodila intuicija.Vremenom je razvijao i usavršavao svoju tehniku. Svoj metod je primenjivao ne samo na ljudima već i na životinjama koje su uzgajali farmeri.
   <br>
   <br>
   Bio je intuitivna, jednostavna, skromna i radna osoba. Sam je izjavljivao da je njegov rad „Dar od Boga“.


  </p>
  </div>
  <div class="card">
      <div class="row" (click)="isVisibleSection2 = !isVisibleSection2">
        <div class="col-lg-10 col-md-10 col-sm-10 col-10">
          <p>Šta je Bowen tehnika?</p>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
          <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection2"></i><i
              class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection2"></i></span>

        </div>
      </div>
    </div>
    <div class="container card-body" *ngIf="isVisibleSection2" >
     <p> Bowen tehnika je holistička, manuelna i neinvazivna tehnika. Oslanja se na sposobnost našeg tela da samo sebe leči i predstavlja jedan od najvažnijih terapijskih koncepata u svetu danas. Zasniva se na teoriji da je u potpunoj relaksaciji tela moguće aktivirati unutrašnju sposobnost i tela i uma da se vrate u balans i harmoniju.

      Bowen je komplementarna tehnika, jer poboljšava i dopunjuje, a ne ometa klasično medicinsko lečenje.

      Nežni Bowen pokreti izvode se na mekim tkivima, mišićima, tetivama, ligamentima i nervnim putevima.
      Obraćamo se telu kao celini i zato je ovo holistička tehnika. Ovim pokretima balansira se rad autonomnog nervnog sistema koji tokom tretmana iz dominacije simpatikusa
      (modaliteta napetosti: bori se ili beži) prelazi u dominaciju parasimpatikusa (modalitet: odmori se, opusti se i isceli), tako da tokom tretmana
      osoba ulazi u stanje duboke opuštenosti, ponekad i zaspi.
     </p>
    </div>

    <div class="card">
        <div class="row" (click)="isVisibleSection3 = !isVisibleSection3">
          <div class="col-lg-10 col-md-10 col-sm-10 col-10">
            <p>Da li vam može pomoći?</p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
            <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection3"></i><i
                class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection3"></i></span>

          </div>
        </div>
      </div>
      <div class="container card-body" *ngIf="isVisibleSection3" >
      <p> Bowen tehnika donosi olakšanje kod mnogih tipova povreda i različitih zdravstvenih problema, kako akutnih tako i hroničnih. Prikladna je i korisna za svakoga: novorođenčad, trudnice, sportiste, starije osobe, kao i za osobe sa invaliditetom. Hronični i neurološki poremećaji su pokazali znake stabilizacije i poboljšanja kvaliteta života sa Bowen tretmanima.
        <br>
        <br>

        Celo telo se duboko relaksira i podstiče se da počne samo da se leči.
<br>
        Ne morate biti bolesni da biste osetili korist od tretmana, jer je uvek “bolje sprečiti nego lečiti “. Svakome je bolje sa Bowenom, jer ne postoje kontraindikacije za primenu i ovaj metod ne može nikako da naškodi. Rezultati koji se postižu kod beba i životinja su vrlo zanimljivi, tako da dokazuju da je Placebo efekat isključen.
<br>
<br>
        S obzirom da podstiče telo na samoizlečenje, dijapazon zdravstvenih problema kod kojih može pomoći je vrlo širok:
<br>
<br></p>

<ul>
  <li> Problemi sa kostima, mišićima i zglobovima , kao što su razne sportske povrede, zamrznuto rame, problemi sa kolenima, kukovima, uganuće skočnog zgloba, bolovi u leđima, išijas, skolioza, kifoza, ukočen vrat, trzajna povreda vrata, ponavljana iščašenja i povrede, itd.</li>
  <li> Poremećene funkcija unutrašnjih organa, kao što su bubrezi, jetra, žučna kesa, želudac, creva i slično.</li>
  <li> Neplodnost, ciste i fibromi na jajnicima, policistični jajnici, endometrioza.</li>
  <li> Hormonalni i emotivni disbalans.</li>
  <li> Stres i sindrom hroničnog umora.</li>
  <li> Glavobolje i migrene.</li>
  <li> Problemi sa disanjem, bronhitis i astma.</li>
  <li> Autoimune bolesti i stanja, razne alergije.</li>
  <li> Neurološki poremećaji kao što su Parkinsonova bolest, moždani udar, multipla skleroza</li>
  <li> Detoksikacija organizma – pomaže imunom i limfnom sistemu.</li>
  <li> Noćno mokrenje kod dece.</li>
  <li> I kod mnogih drugih neravnoteža u telu.</li>

</ul>
<p> Bowenom se telo tretira na svim nivoima: fizičkom, hemijskom, mentalnom i emocionalnom.</p>

     </div>

      <div class="card">
          <div class="row" (click)="isVisibleSection4 = !isVisibleSection4">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
              <p>Kako izgleda tretman?</p>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
              <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection4"></i><i
                  class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection4"></i></span>

            </div>
          </div>
        </div>
        <div class="container card-body" *ngIf="isVisibleSection4" >
           <p> Idealan trenutak za Bowen tretman je neposredno nakon povrede. Na taj način telo dobija mogućnost da prevaziđe traumu, pre nego što počne da pravi protivtežu povredi, preopterećujući tako odgovarajuće mišiće, tetive, ligamente, fasciju i unutrašnje organe preko kojih vrši kompenzaciju. Ako ste u mogućnosti da zakažete Bowen tretman tokom tri dana nakon povrede, izlečenje će biti optimalno brzo i potpuno. Međutim, i u slučaju zanemarenih ili hroničnh stanja, koja nekada traju i godinama, Bowen tehnika daje zavidne rezultate, mada kroz nešto veći broj tretmana.
              <br><br>
            Osoba leži na krevetu ili na masažnom stolu, pokrivena je i u najprijatnijem položaju u kojem može biti. Bowen tretman može se primeniti i u sedećem položaju, ukoliko osoba iz bilo kog razloga ne može da se opusti ležeći.
            <br><br>
            Tretman čine nežni pokreti palčeva i prstiju preko mišića i vezivnog tkiva, bilo direktno na koži ili preko lagane odeće.
            <br><br>
            Između svake serije pokreta, postoji obavezna pauza od nekoliko minuta, kada se osoba odmara, a njeno telo za to vreme obrađuje poruke i pokreće mehanizme samoizlečenja. Tretmani se rade jedanput nedeljno, jer se proces koji je telo započelo tokom tretmana nastavlja narednih pet do sedam dana.
            <br><br>
            Za razliku od mnogih drugih manuelnih terapija, Bowen tehnika ne koristi snažnu manipulaciju i terapeut ne prisiljava telo da radi “kako mu se kaže“, već mu omogućava da pokrene sopstvenu energiju za samoizlečenje u vremenu i po redosledu koji telu odgovara.
           </p>
          <h2>PREPORUKE NAKON TRETMANA</h2>
          <ul>

            <li>Ne podvrgavajte telo nikakvoj manuelnoj ili energetskoj metodi četiri dana pre i pet dana posle tretmana.</li>
            <li>Izbegavajte naporno vežbanje na dan tretmana i dan posle tretmana.</li>
            <li>Ne koristite led ili jaku toplotu na mestu povrede.</li>
            <li>Unosite dosta tečnosti. Voda je najvažnija.</li>
            <li>Šetajte, hodajte, što više, poželjno bar sat dnevno.</li>
          </ul>
          </div>
        <div class="card">
            <div class="row" (click)="isVisibleSection5 = !isVisibleSection5">
              <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                <p>Kućni ljubimci i Bowen?</p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
                <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection5"></i><i
                    class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection5"></i></span>

              </div>
            </div>
          </div>
          <div class="container card-body" *ngIf="isVisibleSection5" >
            <p> Bowen terapija je veoma nežan pristup lečenju životinje u celini, a ne samo nekog odredjenog, obolelog dela. To je holistička, neinvazivna, manuelna tehnika. Nežni Bowen pokreti se izvode preko mišića, tetiva ili nervnih puteva. Ovi nežni pokreti imaju umirujući efekat na nervni sistem životinje i pomažu joj da se telo balansira na svim nivoima, na fizičkom i emocionalno-mentalnom. Može se primeniti na životinjama koje su povredjene, bolesne, zlostavljane ili u fazi rehabilitacije.
              <br><br>
              Bowen tretmanima se takodje deluje na negativno ponašanje i negativna emocionalna stanja. Pokazali su se efikasni u smanjenju strahova, anksioznosti i agresivnosti kod životinja.
              <br><br>
              Bowen tretmani nisu zamena za veterinarsku negu već se primenjuju zajedno sa propisanim oblicima medicinskog lečenja ili samostalno kao preventiva.
              <br><br>
              Ovim tretmanima se poboljšava zdravstveno stanje, smanjuju bolovi i vraća energetski balans. Poboljšanje se postiže već sa 2-3 tretmana.
               <br><br>
               Primenom Bowen terapije smanjuje se nivo bola, podstiče detoksikacija i telo započinje proces ozdravljenja pa je od izuzetne pomoći u sledećim situacijama:
               </p>
               <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                  <ul>

                    <li>Kod povreda vrata ili ledja.</li>
                    <li>Bilo koji problemi sa zglobovima.</li>
                    <li>Kod problema sa hodom.</li>
                    <li>Nakon operacija radi bržeg oporavka.</li>
                    <li>Kod digestivnih problema.</li>
                    <li>Smanjuju se strahovi i agresivnost</li>
                    <li>Radi održavanja forme za izložbe i takmičenja.</li>
                  </ul>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12 card-img">
                  <img src="assets/slider/14.jpg" >
                </div>
               </div>
             
         
          <p>Povremeni, preventivni, tretmani su od velike koristi radi održavanja tela u stanju optimalne ravnoteže, na čemu će vam vaš ljubimac biti veoma zahvalan.</p>
          </div>
          <div class="info-div">
            <div class="col-md-12">
                <img src="assets/infoLogo.png" class="info-logo" >
                <h1>ZAKAŽITE TRETMAN</h1>

              <p>Ukoliko vas interesuje neki od tretmana, svoj termin možete zakazati putem telefona:</p>
              <h2>060/014-99-49</h2>

            </div>

          </div>
