import { User } from './user.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerUrlServices } from 'src/app/shared/server-url.services';

@Injectable()
export class LoginServices {
  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
 // return this.http.post('www.bowensrbija.rs/api/user/signin',
  postContents(postData: User) {
    console.log("postData", postData);
    return this.http.post(this.serverUrlServices.serverApi +'user/signin',
     { email: postData.email, password: postData.password},
     { headers: new HttpHeaders({'X-Requested-With': 'XMLHttpRequest'})});

  }

}
