import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { PhotosServices } from './photos.services';
@Component({
    selector: 'app-photos',
    templateUrl: './photos.component.html',
    styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
    masterImages = [];
    johnWilksomImages = [];
    rejuvanceImages = [];
    bowenImages = [];
    modul7Images = [];
    petsImages = [];
    tcStadiumImages = [];
    firstGeneratioImages = [];
    covid19Images = [];
    georgiIlchevImages = [];

    galleryOptions: NgxGalleryOptions[];

    masterGallery: NgxGalleryImage[];
    johnWilksomGallery: NgxGalleryImage[];
    rejuvanceGallery: NgxGalleryImage[];
    bowenGallery: NgxGalleryImage[];
    modul7Gallery: NgxGalleryImage[];
    petsGallery: NgxGalleryImage[];
    tcStadiumGallery:NgxGalleryImage[];
    firstGenerationGallery:NgxGalleryImage[];
    covid19Gallery:NgxGalleryImage[];
    georgiIlchevGallery:NgxGalleryImage[];
    constructor(private photosServices: PhotosServices) { }

    ngOnInit() {
        this.galleryOptions = [
            {
                width: '100%',
                height: '20vw',
                thumbnailsColumns: 4,
                imageAnimation: NgxGalleryAnimation.Slide,
                image: false
            },
            // max-width 800
            {
                breakpoint: 500,
                width: '100%',
                height: '60vw',
                imagePercent: 80,
                thumbnailsColumns: 2,
                thumbnailsRows: 2,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];

        this.createMasterGallery();
        this.createJonhWilksomGallery();
        this.createRejuvanceGallery();
        this.createBowenGallery();
        this.createModul7Gallery();
        this.createPetsGallery();
        this.createTcStadiumGallery();
        this.createFirstGenerationGallery();
        this.createCovid19Gallery();
        this.createGeorgiIlchevGallery();
    }

  createMasterGallery():void {
    //master galery
    for (let i = 1; i <= 6; i++) {
        this.masterImages.push({
            small: 'assets/gallery/master/' + i + '.jpg',
            medium: 'assets/gallery/master/' + i + '.jpg',
            big: 'assets/gallery/master/' + i + '.jpg'

        });
    }

    this.masterGallery = this.masterImages;
}

createJonhWilksomGallery():void{
    for (let i = 1; i <= 5; i++) {
        this.johnWilksomImages.push({
            small: 'assets/gallery/johnWilksom/' + i + '.jpg',
            medium: 'assets/gallery/johnWilksom/' + i + '.jpg',
            big: 'assets/gallery/johnWilksom/' + i + '.jpg'

        });
    }
    this.johnWilksomGallery = this.johnWilksomImages;
}
createRejuvanceGallery():void{
    for (let i = 1; i <= 3; i++) {
        this.rejuvanceImages.push({
            small: 'assets/gallery/rejuvance/' + i + '.jpg',
            medium: 'assets/gallery/rejuvance/' + i + '.jpg',
            big: 'assets/gallery/rejuvance/' + i + '.jpg'

        });
    }
    this.rejuvanceGallery = this.rejuvanceImages;
}

createBowenGallery():void{
    for (let i = 1; i <= 10; i++) {
        this.bowenImages.push({
            small: 'assets/gallery/bowen/' + i + '.jpg',
            medium: 'assets/gallery/bowen/' + i + '.jpg',
            big: 'assets/gallery/bowen/' + i + '.jpg'

        });
    }
    this.bowenGallery = this.bowenImages;
}
createModul7Gallery():void{
    for (let i = 1; i <= 5; i++) {
        this.modul7Images.push({
            small: 'assets/gallery/modul7/' + i + '.jpg',
            medium: 'assets/gallery/modul7/' + i + '.jpg',
            big: 'assets/gallery/modul7/' + i + '.jpg'

        });
    }
    this.modul7Gallery = this.modul7Images;
}

createPetsGallery():void{
    for (let i = 1; i <= 7; i++) {
        this.petsImages.push({
            small: 'assets/gallery/pets/' + i + '.jpg',
            medium: 'assets/gallery/pets/' + i + '.jpg',
            big: 'assets/gallery/pets/' + i + '.jpg'

        });
    }
    this.petsGallery = this.petsImages;
}
createTcStadiumGallery():void{
    for (let i = 1; i <= 5; i++) {
        this.tcStadiumImages.push({
            small: 'assets/gallery/tcStadium/' + i + '.jpg',
            medium: 'assets/gallery/tcStadium/' + i + '.jpg',
            big: 'assets/gallery/tcStadium/' + i + '.jpg'

        });
    }
    this.tcStadiumGallery = this.tcStadiumImages;
}

createFirstGenerationGallery():void{
    for (let i = 1; i <= 1; i++) {
        this.firstGeneratioImages.push({
            small: 'assets/gallery/firstGeneration/' + i + '.jpg',
            medium: 'assets/gallery/firstGeneration/' + i + '.jpg',
            big: 'assets/gallery/firstGeneration/' + i + '.jpg'

        });
    }
    this.firstGenerationGallery = this.firstGeneratioImages;
}

createCovid19Gallery():void{
    for (let i = 1; i <= 10; i++) {
        this.covid19Images.push({
            small: 'assets/gallery/covid19/' + i + '.jpg',
            medium: 'assets/gallery/covid19/' + i + '.jpg',
            big: 'assets/gallery/covid19/' + i + '.jpg'

        });
    }
    this.covid19Gallery = this.covid19Images;
}
createGeorgiIlchevGallery():void{
    for (let i = 1; i <= 7; i++) {
        this.georgiIlchevImages.push({
            small: 'assets/gallery/georgiIlchev/' + i + '.jpg',
            medium: 'assets/gallery/georgiIlchev/' + i + '.jpg',
            big: 'assets/gallery/georgiIlchev/' + i + '.jpg'

        });
    }
    this.georgiIlchevGallery = this.georgiIlchevImages;
}
}
