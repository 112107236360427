import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Practitioners } from 'src/app/pages/practitioners/practitioners.interface';
import { PractitionersServices } from 'src/app/pages/practitioners/practitioners.services';
import { Places } from 'src/app/shared/places/places.interface';
import { PlacesServices } from 'src/app/shared/places/places.services';

@Component({
  selector: 'app-practitioner',
  templateUrl: './practitioner.component.html',
  styleUrls: ['./practitioner.component.css']
})
export class PractitionerComponent implements OnInit {

  @Input() practitioner: Practitioners;
  @Output() practitionerDeleted: EventEmitter<Practitioners> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  isChecked: boolean = false;
  editValues: Practitioners;
  places: string[] = [];
  cities: string[] = [];
  countries: string[] = [];

  constructor(private practitionersServices: PractitionersServices, private placesServices: PlacesServices) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  onEdit(practitioner: Practitioners) {
    this.onGetPlaces();
    this.practitioner.visible == 1 ? this.isChecked = true : this.isChecked = false;
    this.editValues = practitioner;
    this.isEdit = true;
  }
  onCancel() {
    this.isEdit = false;
    this.isChecked ? this.editValues.visible = 1 : this.editValues.visible = 0;
  }

  onSave(id: number) {
    this.practitioner = this.editValues;
    console.log("practitioner", this.practitioner);
    this.practitionersServices.getEditPractitioner(this.practitioner).subscribe((responseData: Practitioners) => {
    this.practitioner = this.editValues;
    this.editValues = {
      id: null,
      name: '',
      phone: '',
      email: '',
      country: '',
      city: '',
      city2: '',
      visible: null
      }

    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.practitionersServices.deletePractitioner(id).subscribe(
      responseData => {
        this.practitionerDeleted.emit(this.practitioner);
        this.isDeleted = true;
      }
    )
  }

  onGetPlaces() {
    this.placesServices.getPlace().subscribe(responseData=> {
      console.log("[mj]*-PlacesServicesOnGet",responseData);
      const blank :string = " "; 
      this.cities = [blank];
      this.countries = [];
      this.places = responseData;
      
      for (const key in responseData) {
        
        if(this.cities.indexOf(responseData[key].city) === -1){
          this.cities.push(responseData[key].city);
        }
        if(this.countries.indexOf(responseData[key].country) === -1){
          this.countries.push(responseData[key].country);
        }
        
      }
      
    console.log("[mj]*-PlacesServicesOnGet", this.cities);
    });
  }

  onNgModelChange(e){
    e ? this.editValues.visible = 1 : this.editValues.visible = 0;
  }
}
