<h1>Galerija</h1>

<div class="card-footer text-muted">
  <h3>Seminari sa Georgi Ilchevim - Dekodiranje tela Bowenom 1&2, Beograd 2021.god. i 2022.god.</h3>
  <div class="card-footer text-muted">
    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="georgiIlchevGallery"></ngx-gallery>
    </div>
  </div>
  <h3>Bowen seminari u vreme Korone.</h3>
  <div class="card-footer text-muted">
    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="covid19Gallery"></ngx-gallery>
    </div>
  </div>

  <h3>Master seminar sa Ossie i Elaine Rentsch, Bukurešt 20-21.juli 2019.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="masterGallery"></ngx-gallery>
    </div>
  </div>

  <h3>Seminar sa John Wilksom - Tajni život fascije, Beograd,16-17.10.2019.god.</h3>
  <div class="card-footer text-muted">
    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="johnWilksomGallery"></ngx-gallery>
    </div>
  </div>
  <h3>Rejuvance seminar sa Alex Antoniou, Hotel "1000 ruža" na Avali, 15-18.11.2019.god.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="rejuvanceGallery"></ngx-gallery>
    </div>
  </div>

  <h3>Bowen seminari u hotelu "1000 ruža" na Avali.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="bowenGallery"></ngx-gallery>
    </div>
  </div>

  <h3>Proslavljanje položenog ispita za Bowen terapeuta (Modul 7).</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="modul7Gallery"></ngx-gallery>
    </div>
  </div>


  <h3>Kućni ljubimci na Bowen tretmanu.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="petsGallery"></ngx-gallery>
    </div>
  </div>

  <h3>TC Stadion, 08.mart 2017.god, prezentacija Bowen tehnike.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="tcStadiumGallery"></ngx-gallery>
    </div>
  </div>

  <h3>Prva generacija Master terapeuta sa Prof. Andrew Zoppos-om.</h3>
  <div class="card-footer text-muted">

    <div class="row text-center ">
      <ngx-gallery [options]="galleryOptions" [images]="firstGenerationGallery"></ngx-gallery>
    </div>
  </div>

</div>