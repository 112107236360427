import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() currentPage: number;
  @Input() totalPractitioners: number;
  @Input() totalPages: number;
  @Input() pageLimit: number;
  @Output() activePage: EventEmitter<number> = new EventEmitter<number>();
  pages: number[] = [];
  isPreviousVisible: boolean = false;
  isNextVisible: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.pages = Array(parseInt(this.totalPages.toString())).fill(undefined).map((x,i)=>i);
    // this.currentPage = 0;
    // console.log(`[mj]-Page changed to333`,this.currentPage);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.pages = Array(parseInt(this.totalPages.toString())).fill(undefined).map((x,i)=>i);
    console.log("[mj]-ngOnChanges",changes);
  }

  ngAfterContentChecked() {
    if(this.currentPage > 0){
      this.isPreviousVisible = true;
    }else{
      this.isPreviousVisible = false;
    }

    if( this.currentPage < this.pages.length -1){
      this.isNextVisible = true;
    }else{
      this.isNextVisible = false;
    }
    console.log(`Page changed ngAfterViewChecked`,this.currentPage);
    // console.log(`Page changed ngAfterViewChecked`,this.pages.length);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    console.log(`[mj]-Page changed to ${page}`);
    this.activePage.emit(this.currentPage);
  }
  onPagePrevious(): void {
    this.currentPage--;
    this.activePage.emit(this.currentPage);
    console.log(`[mj]-Page changed to333onPagePrevious`,this.activePage);
  }
  onPageNext(): void {
    this.currentPage++
    this.activePage.emit(this.currentPage);
    console.log(`[mj]-Page changed to333onPageNext`,this.currentPage);
  }
}
