<!--<div *ngFor="let video of videos" >
  <iframe width="420" height="315"
  [src]="getVideosUrl(video.video_url)">
  </iframe>*ngFor="let event of events" 
</div>-->
<h1>Video</h1>
<!--<div class="col-lg-12">
  <div class="row text-center ">
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/p0wvtOdidco">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/hkRiDBVcvXU">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/MlU3ojFPmB8">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/6p17D02ZLCg">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/HklGBQSluHk">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/h91YSQlpkIc">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/duT_54GkNPk">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/6FTpktkQZJU">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/FQAxcCDvB5M">
      </iframe>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12">
      <iframe class="video-layout" webkitallowfullscreen mozallowfullscreen allowfullscreen
        src="https://www.youtube.com/embed/8nuOP8uzngE">
      </iframe>
    </div>
    
  </div>
</div>-->




<div class="col-lg-12" >
  <div class="row text-center ">
    <div class="col-lg-3 col-md-4 col-sm-12" *ngFor="let video of videosProtectUrl">
      <iframe class="video-layout" scrolling="no" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen

      [src]='video'>
      </iframe>
    </div>
    
   
  </div>
</div>
