<ul>
  <li>{{quote.content}}

    <span *ngIf="isDeleted"> Deleted!</span>

    <div>
      <div *ngIf="isEdit">
        <input type="text" [(ngModel)]="editValue">

        <button class="btn btn-success" (click)="onSave(quote.id)">Save</button>
        <button class="btn btn-success" (click)="onCancel()">Cancel</button>
      </div>
      <div *ngIf="!isEdit">
        <button class="btn btn-danger" (click)="onDelete(quote.id)">Delete</button>
        <button class="btn btn-success" (click)="onEdit(quote)">Edit</button>
      </div>

    </div>
  </li>

</ul>
