<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr class="bg-info">
                <th scope="col">Slika</th>
                <th scope="col">Naslov</th>
                <th scope="col">Mesto</th>
                <th scope="col">Kratak Detalj</th>
                <th scope="col">Detalji</th>
                <th scope="col">Datum</th>
                <th scope="col">Vreme</th>
                <th scope="col">Izmene</th>
            </tr>

        </thead>


        <tbody>
            <tr>
                <td><img src={{event.image_url}} alt="..." class="img-thumbnail">
                    <div *ngIf="isEdit">
                    
                    </div>
                </td>
                <td>{{event.title}}
                    <div *ngIf="isEdit">
                        <input type="text" [(ngModel)]="editValues.title"><br>
                    </div>
                </td>
                <td>{{event.place}}
                    <div *ngIf="isEdit">
                        <input type="text" [(ngModel)]="editValues.place"><br>
                    </div>
                </td>
                <td>{{event.short_detail}}
                    <div *ngIf="isEdit">
                        <textarea type="text" class="form-control" rows="6" [(ngModel)]="event.short_detail" ></textarea><br>
                    </div>
                </td>
                <td>{{event.detail}}
                    <div *ngIf="isEdit">
                        <textarea type="text" class="form-control" rows="6" [(ngModel)]="editValues.detail" ></textarea><br>
                    </div>
                </td>
                <td>{{event.date}}
                    <div *ngIf="isEdit">
                        <input type="text" [(ngModel)]="editValues.date"><br>
                    </div>
                </td>
                <td>{{event.time}}
                    <div *ngIf="isEdit">
                        <input type="text" [(ngModel)]="editValues.time"><br>
                    </div>
                </td>
                <td>
                    <div>
                        <div *ngIf="isEdit">
                            <button class="btn btn-info" (click)="onSave(event.id)">Sačuvaj</button>
                            <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
                        </div>
                        <div *ngIf="!isEdit">
                            <button class="btn btn-danger" (click)="onDelete(event.id)">Obriši</button>
                            <button class="btn btn-success button_padding" (click)="onEdit(event)">Izmeni</button>
                        </div>

                    </div>
                </td>
            </tr>

        </tbody>


    </table>
</div>