import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Events } from '../events/events.interface';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';
import { EventsServices } from '../events/events.service';
@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  events:Events[]=[];
  title:string = '';
  isMailSent:boolean = false;
  contactForm: FormGroup;
  constructor(private _Activatedroute:ActivatedRoute,
    private eventsServices:EventsServices  
  ){
}

  ngOnInit() {
    this.contactForm = new FormGroup({
      'title': new FormControl(null),
      'name': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'phone': new FormControl(null),
      'message': new FormControl(null)
    });
    this.getEventDetail();
    
  }
  onSubmit(){
    this.contactForm.controls['title'].setValue(this.title);
    this.eventsServices.postEmail(this.contactForm.value).subscribe(
      responseData => {
        if(responseData){
          this.isMailSent = true;
        }else{
          this.isMailSent = false;
        }
      }
    );;
    this.contactForm.reset();
    this.resetSubmitedPopup();
  }

  resetSubmitedPopup(): void {
    setTimeout(() => {
      this.isMailSent = false;
    }, 5000);
  }
  getEventDetail(){
    this._Activatedroute.paramMap.subscribe(params => { 
      let id = params.get('id');
      this.title = id;
      this.eventsServices.getEvent().subscribe(responseData => {
         this.events = responseData;
        for (const key in responseData) {
          if (responseData[key].detail.indexOf(',') > -1) {
            const item = responseData[key].detail.replace(new RegExp(';', 'g'), '\n');
            responseData[key].detail = item;
          }
        }
       });
   });
  }
}
