import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bowen-technique',
  templateUrl: './bowen-technique.component.html',
  styleUrls: ['./bowen-technique.component.css']
})
export class BowenTechniqueComponent implements OnInit {

 public isVisibleSection1 = false;
 public isVisibleSection2 = false;
 public isVisibleSection3 = false;
 public isVisibleSection4 = false;
 public isVisibleSection5 = false;

  constructor() { }

  ngOnInit() {
  }

}
