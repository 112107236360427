import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Credentials } from './user.interface';
import { Component, OnInit, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginServices } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  credentialsData: any;
  constructor(private loginService: LoginServices, private router: Router, private authService: AuthService) { }

  ngOnInit() {

    this.loginForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    });
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    this.loginService.postContents(this.loginForm.value).subscribe(
      responseData => {
        this.credentialsData = responseData;
        if (this.credentialsData.auth) {
          this.authService.login();
          console.log("Login-Correct");
          this.router.navigate(['bowen/login/admin']);
        } else {
          this.authService.logout();
          console.log("incorect-");
        }
        this.loginForm.reset();
      }
    );
  }

}
