<h1>{{title}}</h1>
<div class="container">
    <div class="col-lg-12" *ngFor="let event of events" >
        <div class="row text-center " *ngIf="event.title == title">
          <div class="col-lg-6 col-md-8 col-sm-12 instructors-table card">
            <img class="profile-image" src="{{event.image_url}}" alt="..." class="img-thumbnail">
            <div class="row">
              
             
            </div>
            <hr>
            <div class="col-lg-12 white-space" >
              <p class="detail-text">{{event.detail}}</p>
        
            </div>
            
          </div>
          <div class="col-lg-6 col-md-4 col-sm-12">
            <div class="alert alert-success animated fadeIn" role="alert"  *ngIf="isMailSent">
              Hvala što ste se prijavili za događaj!
            </div>
                <form class="card" [formGroup]="contactForm" (ngSubmit)="onSubmit()" >
                  <div class="form-body">
                    <div class="form-header">
                      <h2>Prijavite se za događaj</h2>
                    </div>
                    <div class="form-group">
                      <div class="row">
            
                        <div class="col-lg-1">
                          <i class="fas fa-user fa-2x"></i>
                        </div>
                        <div class="col-lg-11">
                          <input type="text" class="form-control" formControlName="name" id="name" placeholder="Vaše ime">
                          <span class="help-block" *ngIf="!contactForm.get('name').valid && contactForm.get('name').touched" >Popunite polje za ime!</span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
            
                        <div class="col-lg-1">
                          <i class="fas fa-envelope fa-2x"></i>
                        </div>
                        <div class="col-lg-11">
                          <input type="email" class="form-control" formControlName="email" id="email" placeholder="Vaš email">
                          <span class="help-block" *ngIf="!contactForm.get('email').valid && contactForm.get('email').touched" >Popunite polje za email!</span>
                        </div>
                      </div>
                    </div>
                     <div class="form-group">
                        <div class="row">
            
                          <div class="col-lg-1">
                            <i class="fas fa-mobile-alt fa-2x"></i>
                          </div>
                          <div class="col-lg-11">
                            <input type="text" class="form-control" formControlName="phone" id="phone" placeholder="Vaš telefon">
                          </div>
                        </div>
                      </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-1">
                          <i class="fas fa-pencil-alt fa-2x"></i>
                        </div>
                        <div class="col-lg-11">
                          <textarea class="form-control" formControlName="message" id="message" placeholder="Vaša poruka"
                            rows="5"></textarea> </div>
                      </div>
                    </div>
                    <div class="submit-button">
                      <button type="submit" class="btn btn-layout" [disabled]="!contactForm.valid" >Pošalji</button>
                    </div>
            
                  </div>
                  <div class="row contact-info">
            
                    <div class="col-lg-6">
                        <a class="icon-floating icon-floating-bck">  <i class="fas fa-phone fa-2x"></i></a>
                      <p>060/014-99-49</p>
                    </div>
                    <div class="col-lg-6">
                        <a class="icon-floating icon-floating-bck"> <i class="fas fa-envelope fa-2x"></i></a>
                      <p>info@bowensrbija.rs</p>
                    </div>
            
                  </div>
                </form>
          </div>
      
        </div>
      </div>
    </div>
