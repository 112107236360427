import { Component, OnInit, Input } from '@angular/core';
import { Events } from './events.interface';
import { EventsServices } from './events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  events:Events[] = [];
  isDetails = false;
  constructor( private eventsServices: EventsServices) { }

  ngOnInit() {

  /*  this.events = [
      {'title' : 'Bowen kao prevencija',
      'image_url':'assets/index4.jpg',
    'date':'24.07',
    'time':'11:05',
    'place':'Healin holistic hub, Smiljanićeva 15, 11000 Beograd'},
    {'title' : 'Bowen kao prevencija',
    'image_url':'assets/index4.jpg',
  'date':'24.07',
  'time':'11:05',
  'place':'Healin holistic hub, Smiljanićeva 15, 11000 Beograd2'}];
   */

  this.onGetEvents();
}

  //Events

  onGetEvents():void {
    this.eventsServices.getEvent().subscribe(responseData => {
      responseData.reverse();
      this.events = responseData;
    });
  }
  onDetails(e:Events): void{
  }
}
