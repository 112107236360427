import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private route: Router){}
 canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   return this.authService.isAuthenticated().then(
      (auth: boolean) => {
        if(auth) {
          //console.log("AUTH", auth);
          return true;
        } else {

         //console.log("AUTH2", auth);
          this.route.navigate(['bowen/login']);
          return false;
        }
      }
    )

  }
}
