import { DomSanitizer } from '@angular/platform-browser';
import { ServerUrlServices } from './../../../shared/server-url.services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {

 public isVisibleSection1: boolean = false;
 public isVisibleSection2: boolean = false;
 public isVisibleSection3: boolean = false;
 public isVisibleSection4: boolean = false;
 public isVisibleSection5: boolean = false;
 public isVisibleSection7: boolean = false;
 public isVisibleSection8: boolean = false;
  serverFile1: any = '';
  serverFile2: any = '';
  serverFile3: any = '';
  serverFile4: any = '';
  constructor(private serverUrlServices: ServerUrlServices, private senitizer: DomSanitizer) { }

  ngOnInit() {

    this.serverFile1 = this.senitizer.bypassSecurityTrustResourceUrl(this.serverUrlServices.serverStorage + 'pdf/zoposa.pdf');
    this.serverFile2 = this.senitizer.bypassSecurityTrustResourceUrl(this.serverUrlServices.serverStorage + 'pdf/antoniou.pdf');
    this.serverFile3 = this.senitizer.bypassSecurityTrustResourceUrl(this.serverUrlServices.serverStorage + 'pdf/antoniou.pdf');
    this.serverFile4 = this.senitizer.bypassSecurityTrustResourceUrl(this.serverUrlServices.serverStorage + 'pdf/antoniou.pdf');

  }

  openNewTab() {
    window.open('https://www.blic.rs/slobodno-vreme/vesti/neobicna-tehnika-koja-pomaze-da-psu-izlecite-povredu-ili-smanjite-agresivnost/17rmfg1', '_blank');
  }
}
