<h1>Obuka</h1>
<div class="container card-body-main" >

  <br>
    <p>
      Rejuvance se lako uči i lako primenjuje. Obuka za Rejuvance praktičara sprovodi se kroz četvorodnevni seminar (32 sata). <br>
        <br>
        Na seminaru se dobija priručnik na srpskom jeziku sa detaljnim i sveobuhvatnim objašnjenjima. <br>
          <br>
          Po završetku seminara dobijate sertifikat o pohadjanju, a po slanju 4 prikaza rezultata uredjenih tretmana stičete pravo da se promovišete na našem zvaničnom sajtu www.rejuvance.rs.   <br>
          <br>
          Cena seminara: 60.000,00 din.
          <br>
          <br>
          Da biste sebi osigurali mesto na seminaru, potrebno je uplatiti depozit mesec dana pre početka seminara. U slučaju otkaza neophodno je obavestiti organizatora najkasnije dve nedelje pre početka kursa, u suprotnom depozit se ne vraća.<br>
          <br>
          Po pravilima i po zakonu o zaštiti podataka, tokom predavanja se očekuje poštovanje privatnosti svih prisutnih. To znači da nije dozvoljeno snimanje tona/glasa, ni fotografisanje bez prethodnog odobrenja ostalih učesnika/ca za vreme trajanja seminara, bilo da se snimanje odvija profesionalnim uređajima ili mobilnim telefonima. Takođe, komunikacija mobilnim telefonima dozvoljena je u pauzama ili van mesta održavanja predavanja. <br>
          <br></p>
          <p align="left">  Kontraindikacije:<br><img src="assets/logoRejPng.png"> Problemi sa kožom ( akne, čirevi, bradavice..)<br><img src="assets/logoRejPng.png"> Hemoterapija <br><img src="assets/logoRejPng.png"> Trudnoća<br><img src="assets/logoRejPng.png"> Teška psihička stanja<br><img src="assets/logoRejPng.png"> Dijabetes<br><img src="assets/logoRejPng.png"> Epilepsija<br><img src="assets/logoRejPng.png"> Aplikacije Botox-a ili hijaluronske kiseline (ukoliko je period od aplikacije kraći od 6 meseci)
          <br>
          <br>
         Za prisustvo na Rejuvance seminaru potrebno je sledeće:<br><img src="assets/logoRejPng.png"> Kratko isečeni nokti (nakon pokazivanja pokreti se uče tako što vežbate jedni na drugima pa je važno da ne ostavite ogrebotine na koži kolega)<br><img src="assets/logoRejPng.png"> Udobna odeća <br><img src="assets/logoRejPng.png"> Dva velika peškira (ili čaršav i veliki peškir)<br><img src="assets/logoRejPng.png"> Sveska za belešk<br><img src="assets/logoRejPng.png"> Dobro raspoloženje!
        </p> 
        <p>
        <br>Seminar se održava u Hotelu „1000 ruža“ u vremenu od 9-17h. Pauze u prepodnevnim i po podnevnim časovima, obezbeđuje organizator, dok ste Vi u obavezi da obezbedite sebi ručak za vreme velike pauze. Naravno, u hotelu postoji restoran sa dosta povoljnim cenama ili možete poneti nešto sa sobom.
          <br>
          <br>Vidimo se uskoro! </p>
  </div>
