import { InstructorsServices } from './../../../seminars/instructors/instructors.services';
import { Instructors } from './../../../seminars/instructors/instructors.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.css']
})
export class InstructorComponent implements OnInit {

  @Input() instructor: Instructors;
  @Output() instructorDeleted: EventEmitter<Instructors> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValues: Instructors ;
  constructor(private instructorsServices: InstructorsServices) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  onEdit(instructor: Instructors) {
    this.isEdit = true;
    this.editValues = instructor;
   // this.editValue = quote.content;

  }
  onCancel() {
   // this.editValues = {};
    this.isEdit = false;
  }
  onFileSelected(event) {

    this.editValues.image = event.target.files[0];

  }

  onSave(id: number) {
    this.instructor = this.editValues;
    console.log("instructor", this.instructor);
    this.instructorsServices.getEditInstructor(this.instructor).subscribe((responseData: Instructors) => {
      this.instructor = this.editValues;
    this.editValues = {
      id: null,
      image: null,
       name: '',
       image_url: '',
       content: ''
      }

    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.instructorsServices.deleteInstructor(id).subscribe(
      responseData => {
        this.instructorDeleted.emit(this.instructor);
        this.isDeleted = true;
      }
    )
  }
}
