import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactServices } from './contact.services';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  isMailSent:boolean = false;
  constructor(private contactServices: ContactServices) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      'name': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'subject': new FormControl(null),
      'content': new FormControl(null, Validators.required)
    });

  }

  onSubmit(){
    //this.contactForm.reset();
    this.contactServices.postEmail(this.contactForm.value).subscribe(
      responseData => {
        if(responseData){
          this.isMailSent = true;
        }else{
          this.isMailSent = false;
        }
      }
    );
    this.contactForm.reset();
    this.resetSubmitedPopup();
  }

  resetSubmitedPopup(): void {
    setTimeout(() => {
      this.isMailSent = false;
    }, 5000);
  }
}
