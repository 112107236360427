
import { ServerUrlServices } from './shared/server-url.services';
import { VideoServices } from './pages/news/videos/video.service';
import { InstructorsServices } from './pages/seminars/instructors/instructors.services';
import { AuthService } from './pages/login/auth.service';
import { AboutUsServices } from './pages/about-us/about-us.service';
import { DropdownDirective } from './shared/dropdown.directive';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { from } from 'rxjs';
import { HeaderComponent } from './navbars/header/header.component';
import { FooterComponent } from './navbars/footer/footer.component';
import { IndexComponent } from './pages/index/index.component';
import { BowenTechniqueComponent } from './pages/bowen-technique/bowen-technique.component';
import { SeminarsComponent } from './pages/seminars/seminars.component';
import { NewsComponent } from './pages/news/news.component';
import { HolisticCenterComponent } from './pages/holistic-center/holistic-center.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { TrainingCourseComponent } from './pages/seminars/training-course/training-course.component';
import { TeachingProgramComponent } from './pages/seminars/teaching-program/teaching-program.component';
import { UpcomingSeminarsComponent } from './pages/seminars/upcoming-seminars/upcoming-seminars.component';
import { InstructorsComponent } from './pages/seminars/instructors/instructors.component';
import { BtaaComponent } from './pages/seminars/btaa/btaa.component';
import { EventsComponent } from './pages/news/events/events.component';
import { PublicationsComponent } from './pages/news/publications/publications.component';
import { VideoComponent } from './pages/news/videos/video.component';
import { TreatmentsComponent } from './pages/holistic-center/treatments/treatments.component';
import { BahovDropsComponent } from './pages/holistic-center/bahov-drops/bahov-drops.component';
import { AquaDetoxComponent } from './pages/holistic-center/aqua-detox/aqua-detox.component';
import { ReikiComponent } from './pages/holistic-center/reiki/reiki.component';
import { AtlasComponent } from './pages/holistic-center/atlas/atlas.component';
import { TeamMembersComponent } from './pages/about-us/team-members/team-members.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { QuoteComponent } from './pages/quote/quote.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginServices } from './pages/login/login.service';
import { AdminComponent } from './pages/login/admin/admin.component';
import { AuthGuard } from './pages/login/auth-guard.service';
import { SeminarsServices } from './pages/seminars/upcoming-seminars/seminars.service';
import { SeminarComponent } from './pages/login/admin/seminar/seminar.component';
import { InstructorComponent } from './pages/login/admin/instructor/instructor.component';
import { RejuvanceComponent } from './pages/rejuvance/rejuvance.component';
import { PhotosComponent } from './pages/news/photos/photos.component';
import { RejuvanceSeminarsComponent } from './pages/rejuvance-seminars/rejuvance-seminars.component';
import { RejUpcomingSeminarsComponent } from './pages/rejuvance-seminars/rej-upcoming-seminars/rej-upcoming-seminars.component';
import { RejInstructorsComponent } from './pages/rejuvance-seminars/rej-instructors/rej-instructors.component';
import { RejTrainingComponent } from './pages/rejuvance-seminars/rej-training/rej-training.component';
import { AboutStankaComponent } from './pages/about-stanka/about-stanka.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { PhotosServices } from './pages/news/photos/photos.services';
import { EventComponent } from './pages/login/admin/event/event.component';
import { EventsServices } from './pages/news/events/events.service';
import { ContactServices } from './pages/contact/contact.services';
import { EventDetailComponent } from './pages/news/event-detail/event-detail.component';
import { VideoAdminComponent } from './pages/login/admin/video/video-admin.component';
import { PractitionerComponent } from './pages/login/admin/practitioner/practitioner.component';
import { PlacesServices } from './shared/places/places.services';
import { PlaceComponent } from './pages/login/admin/place/place.component';
import { PractitionersComponent } from './pages/practitioners/practitioners.component';
import { PaginationComponent } from './pages/practitioners/pagination/pagination.component';
import { PractitionersServices } from './pages/practitioners/practitioners.services';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    BowenTechniqueComponent,
    SeminarsComponent,
    NewsComponent,
    HolisticCenterComponent,
    AboutUsComponent,
    TrainingCourseComponent,
    TeachingProgramComponent,
    UpcomingSeminarsComponent,
    InstructorsComponent,
    BtaaComponent,
    EventsComponent,
    PublicationsComponent,
    VideoComponent,
    TreatmentsComponent,
    BahovDropsComponent,
    AquaDetoxComponent,
    ReikiComponent,
    AtlasComponent,
    DropdownDirective,
    TeamMembersComponent,
    ContactComponent,
    QuoteComponent,
    LoginComponent,
    AdminComponent,
    SeminarComponent,
    InstructorComponent,
    RejuvanceComponent,
    PhotosComponent,
    RejuvanceSeminarsComponent,
    RejUpcomingSeminarsComponent,
    RejInstructorsComponent,
    RejTrainingComponent,
    AboutStankaComponent,
    EventComponent,
    EventDetailComponent,
    VideoAdminComponent,
    PractitionersComponent,
    PaginationComponent,
    PractitionerComponent,
    PlaceComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxGalleryModule
  ],
  providers: [AboutUsServices, LoginServices, SeminarsServices, AuthService, AuthGuard, InstructorsServices, VideoServices, ServerUrlServices, PhotosServices, EventsServices, ContactServices, PractitionersServices, PlacesServices],
  bootstrap: [AppComponent]
})
export class AppModule { }
