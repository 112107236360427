<div class="table-responsive">
    <table class="table table-bordered practitionerTable">
      <thead>
        <tr class="bg-info">
          <th scope="col">Ime</th>
          <th scope="col">Telefon</th>
          <th scope="col">Email</th>
          <th scope="col">Zemlja</th>
          <th scope="col">Grad</th>
          <th scope="col">Grad 2</th>
          <th scope="col">Članarina</th>
          <th scope="col">Izmene</th>
        </tr>
  
      </thead>
  
  
      <tbody>
        <tr>
          <td>{{practitioner.name}}
            <div *ngIf="isEdit">
              <input type="text" [(ngModel)]="editValues.name"><br>
            </div>
          </td>
          <td>{{practitioner.phone}}
            <div *ngIf="isEdit">
              <input type="text" [(ngModel)]="editValues.phone"><br>
            </div>
          </td>
          <td>{{practitioner.email}}
            <div *ngIf="isEdit">
              <input type="text" [(ngModel)]="editValues.email"><br>
            </div>
          </td>
          <td>{{practitioner.country}}
            <div *ngIf="isEdit">
              <div class="form-group" >
                <label style="margin-right: 4px">Zemlja: </label>
                    <select class="form-control" id="country" [(ngModel)]="editValues.country">
                      <option *ngFor="let country of countries" [selected] = "country == editValues.country">
                        {{country}}
                     </option>
                    </select>
            </div>
            </div>
          </td>
          <td>{{practitioner.city}}
            <div *ngIf="isEdit">
              <div class="form-group" >
                <label style="margin-right: 4px">Grad: </label>
                    <select class="form-control" id="city" [(ngModel)]="editValues.city">
                      <option *ngFor="let city of cities" [selected] = "city == editValues.city">
                        {{city}}
                     </option>
                    </select>
            </div>
            </div>
          </td>
          <td>{{practitioner.city2}}
            <div *ngIf="isEdit">
              <div class="form-group" >
                <label style="margin-right: 4px">Grad2: </label>
                    <select class="form-control" id="city2" [(ngModel)]="editValues.city2">
                      <option *ngFor="let city2 of cities" [selected] = "city2 == editValues.city2">
                        {{city2}}
                     </option>
                    </select>
            </div>
            </div>
          </td>
          <td>
            <div>
              <div class="form-group" >
                <input type="checkbox" name="visible" class="form-control" id="visible" [disabled]="!isEdit" [ngModel]="practitioner.visible == 1" (ngModelChange)="onNgModelChange($event)">
                
            </div>
            </div>
          </td>
          <td>
            <div class="buttons">
              <div *ngIf="isEdit">
                <button class="btn btn-info" (click)="onSave(practitioner.id)">Sačuvaj</button>
                <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
              </div>
              <div *ngIf="!isEdit">
                <button class="btn btn-danger" (click)="onDelete(practitioner.id)">Obriši</button>
                <button class="btn btn-success button_padding" (click)="onEdit(practitioner)">Izmeni</button>
              </div>
  
            </div>
          </td>
        </tr>
  
      </tbody>
  
  
    </table>
  </div>
  