import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-course',
  templateUrl: './training-course.component.html',
  styleUrls: ['./training-course.component.css']
})
export class TrainingCourseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
