
<h1>Instruktori</h1>
<div class="container"  *ngFor="let instructor of instructors">
  <div class="row">
    <div class="col-lg-2  instructors-table">
      <img class="profile-image" src="{{instructor.image_url}}" alt="..." class="img-thumbnail">
    </div>

    <div class="col-lg-10 card-body-main">
      <h2>{{instructor.name}}
         <span   *ngFor="let star of stars | keyvalue">
           <span *ngIf="instructor.name == star.key">
              <span *ngFor="let starImg of arrayStar(star.value)">
                  <img   src="assets/star.png">
              </span>
           </span>
       
          </span>
      </h2>
      <p>{{instructor.content}}</p>
    </div>
  </div>
<br>
</div>
