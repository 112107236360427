import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Places } from 'src/app/shared/places/places.interface';
import { PlacesServices } from 'src/app/shared/places/places.services';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.css']
})
export class PlaceComponent implements OnInit {

  @Input() place: Places;
  @Output() placeDeleted: EventEmitter<Places> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValues: Places ;
  constructor(private placesServices: PlacesServices) { }

  ngOnInit() {
    // console.log("[mj]-Laalalala",this.countriesServices.getCountries())
    // this.countriesServices.getCountries().subscribe(message => {
    //   //here message is the array                
    //        });
    // this.countries = this.countriesServices.countries;
    // this.cities = this.countriesServices.cities;
  }
  ngOnChanges() {
  }
  onEdit(place: Places) {
    // console.log("[mj]-Laalalala22",this.countriesServices.countries)
    this.isEdit = true;
    this.editValues = place;

  }
  onCancel() {
   // this.editValues = {};
    this.isEdit = false;
  }

  onSave(id: number) {
    this.place = this.editValues;
    console.log("practitioner", this.place);
    this.placesServices.getEditPlace(this.place).subscribe((responseData: Places) => {
    this.place = this.editValues;
    this.editValues = {
      id: null,
      country: '',
      city: ''
      }

    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.placesServices.deletePlace(id).subscribe(
      responseData => {
        console.log("placesResponseData", responseData);
        this.placeDeleted.emit(this.place);
        this.isDeleted = true;
      }
    )
  }

}
