import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServerUrlServices } from 'src/app/shared/server-url.services';
import { Contact } from './contact.interface';

@Injectable()
export class ContactServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
 
  postEmail( postData: Contact) {
    console.log("postEmailData", postData);
    return this.http.post<{ [key: string]: Contact }>(this.serverUrlServices.serverApi + 'mail', postData);
  }

}
