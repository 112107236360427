<div *ngIf="isLoggedIn">
<div class="col-lg-4 form-container">
  <form class="card" [formGroup]="quotesForm" (ngSubmit)="onSubmit()" >
    <div class="form-body">
      <div class="form-header">
        <h2>Pišite nam:</h2>
      </div>
      <div class="form-group">
        <div class="row">

          <div class="col-lg-1">
            <i class="fas fa-user fa-2x"></i>
          </div>
          <input type="text" class="form-control" formControlName="id" id="id" placeholder="id">
          <div class="col-lg-11">
            <input type="text" class="form-control" formControlName="content" id="content" placeholder="Vaše ime">
            <span class="help-block" *ngIf="!quotesForm.get('content').valid && quotesForm.get('content').touched" >Popunite polje za ime!</span>
          </div>
        </div>
      </div>
      <div class="submit-button">
        <button type="submit" class="btn btn-primary" [disabled]="!quotesForm.valid" >Submit</button>
      </div>

    </div><input type="datetime-local" name="bdaytime">
  </form>

</div>

<button (click)="onGetContent()">Get Contnet</button>
<div>

    <app-quote *ngFor="let quote of quotes " [quote]="quote" (quoteDeleted)="onDeleted($event)"> </app-quote>


</div>


</div>
