<h1>Bowen praktičari</h1>


<div class="container card-body-main">
    <div class="row infoText">
        <p>Svi Bowen praktičari nabrojani na ovom sajtu su u potpunosti akreditovani od strane Bowen Terapijske
            Akademije Australije (BTAA – Bowen Therapy Academy of Australia).</p>
    </div>
    <form class="card-body-main" [formGroup]="countryForm" (ngSubmit)="onSubmitCountryForm()">
        <div class="row ">
            <div class="col-lg-3 col-md-3 col-xs-3 ml-auto">

                <div class="form-group">
                    <label style="margin-right: 4px">Zemlja: </label>
                    <select class="form-control" id="country" formControlName="country">
                        <option value="all">Sve</option>
                        <option *ngFor="let country of countries">
                            {{country}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-xs-3 mr-auto">
                <div class="form-group">
                    <label style="margin-right: 4px">Grad: </label>
                    <select class="form-control" id="city" formControlName="city">
                        <option value="all" selected>Svi</option>
                        <option *ngFor="let city of cities">
                            {{city}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="submit-button">
                    <button type="submit" class="btn btn-layout">PRETRAŽI</button>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-xs-6">
                <div class="submit-button submit-button-right">
                    <button type="button" class="btn btn-layout" (click) = "onSeeAll()">POGLEDAJ SVE</button>
                </div>
            </div>  -->

        </div>
    </form>
    <div class="row">
        <div class="div mx-auto" style="padding-top: 10px;">
            <p>Prikazuje {{pageLimit <= totalPractitioners ? pageLimit : totalPractitioners}} praktičara od {{totalPractitioners}}</p>
        </div>
    </div>
    <!-- PAGINATION -->
    <app-pagination [currentPage]="currentPage" [totalPractitioners]="totalPractitioners" [totalPages]="totalPages"
        [pageLimit]="pageLimit" (activePage)="onActivePage($event)"></app-pagination>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-body-main practitioner-table mx-auto"
            *ngFor="let practitioner of getActivePage(activePage)">
            <div class="practitioner-card">
                <h2>{{practitioner.name}}
                    <span *ngFor="let star of stars | keyvalue">
                        <span *ngIf="practitioner.name == star.key">
                            <span *ngFor="let starImg of arrayStar(star.value)">
                                <img src="assets/star.png">
                            </span>
                        </span>

                    </span>
                </h2>
                <!-- <p>ID: {{practitioner.id}}</p> -->
                <p><strong><i>Tel:</i></strong> {{practitioner.phone}}</p>
                <p><strong><i>Email:</i></strong> {{practitioner.email}}</p>
                <p><strong><i>Grad:</i></strong> {{practitioner.city}}{{practitioner.city2 !== null &&  practitioner.city2 !== "null"? "," : " "}} {{practitioner.city2 !== null &&  practitioner.city2 !== "null"? practitioner.city2 : " "}}</p>
                <p><strong><i>Država:</i></strong> {{practitioner.country}}</p>
            </div>
            <br>
        </div>

    </div>
    <!-- PAGINATION BOTTOM
    <app-pagination [currentPage]="currentPage" [totalPractitioners]="totalPractitioners" [totalPages]="totalPages"
        [pageLimit]="pageLimit" (activePage)="onActivePage($event)"></app-pagination> -->
    <br>
    <div class="container card-body-main">
        <div class="row infoStars">
            <p><img src="assets/star.png"> Zvezdica pored imena praktičara ukazuje da je Bowen praktičar procenjen kao kompetentan na seminaru naprednih procedura.</p>
            <p><img src="assets/star.png"><img src="assets/star.png"> Dve zvezdice pored imena praktičara ukazuju da je Bowen praktičar procenjen kao kompetentan na seminaru master procedura.</p>
            <p><img src="assets/star.png"><img src="assets/star.png"><img src="assets/star.png"> Tri zvezdice pored imena praktičara ukazuje da je Bowen instruktor akreditovan od strane Bowen Terapijske Akademije Austraije (BTTA).</p>
            <p><img src="assets/star.png"><img src="assets/star.png"><img src="assets/star.png"><img src="assets/star.png"> Viši instruktor.</p>
        </div>
    </div>  
    <br>
   
</div>