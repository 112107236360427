export class AuthService {
  loggedIn = false;

  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        resolve(this.loggedIn);
      }
    );
    return promise;
  }
  login() {
    this.loggedIn = true;
    //console.log("[mj]-login", this.loggedIn);
  }

  logout() {
    this.loggedIn = false;
   //console.log("[mj]-Logout", this.loggedIn);
  }
}
