<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr class="bg-info">
        <th scope="col">Slika</th>
        <th scope="col">Ime</th>
        <th scope="col">Sadrzaj</th>
        <th scope="col">Izmene</th>
      </tr>

    </thead>


    <tbody>
      <tr>
        <td><img src={{instructor.image_url}} alt="..." class="img-thumbnail">
          <div *ngIf="isEdit">
              <input type="file" name="image" class="form-controler" id="image" (change)="onFileSelected($event)">

          </div>
        </td>
        <td>{{instructor.name}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.name"><br>
          </div>
        </td>
        <td>{{instructor.content}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.content"><br>
          </div>
        </td>
        <td>
          <div>
            <div *ngIf="isEdit">
              <button class="btn btn-info" (click)="onSave(instructor.id)">Sačuvaj</button>
              <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
            </div>
            <div *ngIf="!isEdit">
              <button class="btn btn-danger" (click)="onDelete(instructor.id)">Obriši</button>
              <button class="btn btn-success button_padding" (click)="onEdit(instructor)">Izmeni</button>
            </div>

          </div>
        </td>
      </tr>

    </tbody>


  </table>
</div>
