import { Seminars } from './../../../seminars/upcoming-seminars/seminars.interface';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SeminarsServices } from 'src/app/pages/seminars/upcoming-seminars/seminars.service';

@Component({
  selector: 'app-seminar',
  templateUrl: './seminar.component.html',
  styleUrls: ['./seminar.component.css']
})
export class SeminarComponent implements OnInit {
  @Input() seminar: Seminars;
  @Output() seminarDeleted: EventEmitter<Seminars> = new EventEmitter();
  isDeleted: boolean = false;
  isEdit: boolean = false;
  editValues: Seminars ;
  constructor(private seminarsServices: SeminarsServices) { }

  ngOnInit() {
  }
  ngOnChanges() {
   
  }
  onEdit(seminar: Seminars) {
    this.isEdit = true;
    this.editValues = seminar;
   // this.editValue = quote.content;

  }
  onCancel() {
   // this.editValues = {};
    this.isEdit = false;
  }

  onSave(id: number) {
    this.seminar = this.editValues;
    console.log("seminar", this.seminar);
    this.seminarsServices.getEditContent(this.seminar).subscribe((responseData: Seminars) => {
      this.seminar = this.editValues;
      this.editValues = {
      id: null,
       title: '',
       town: '',
       seminars: '',
       group: '',
       instructors: '',
       date: '',
       time: '',
      };

    });
    this.isEdit = false;
  }
  onDelete(id: number) {
    this.seminarsServices.deleteContent(id).subscribe(
      responseData => {
        this.seminarDeleted.emit(this.seminar);
        this.isDeleted = true;
      }
    )
  }
}
