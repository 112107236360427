<div class="row">
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 info">
    <p class="icon-floating icon-floating-bck">  <i class="fas fa-phone"></i></p>
    <p class="icon-info">060/014-99-49</p>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
    <a  routerLink="/index"><img class="logo-normal" src="assets/logBowen3.png" alt="Hempak -logo" class="logo" (click)="isSeminarsActivated = false || isNewsActivated =false || isRejSeminarsActivated= false || navbarOpen = false"></a>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 info mail-info">
    <a class="icon-floating icon-floating-bck"> <i class="fas fa-envelope fa-2x"></i></a>
    <p class="icon-info">info@bowensrbija.rs</p>
  </div>
</div>
<nav
  class="navbar navbar-expand-lg navbar-light sticky-top" id="header" #stickyActive >
  <button class="navbar-toggler " type="button" (click)="toggleNavbar()" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="mobile-icon" >
      <i class="fas fa-bars fa-2x"></i>
    </span>
  </button>
  <div class="collapse navbar-collapse " id="navbarTogglerDemo01" [ngClass]="{ 'show': navbarOpen }">
   <!-- <div class="col-sm-1">
        <a  routerLink="/index"><img width="10" height="80" src="assets/navLogo2.png" alt="Hempak -logo" class="nav-logo" #navLogo ></a>
    </div> -->
   <!-- <div  class="col-lg-10 col-sm-12">-->
    <ul class="nav nav-pills nav-fill mx-auto"  >
      <li class="nav-item" >
        <a class="nav-link" routerLinkActive="active" routerLink="/index" [routerLinkActiveOptions]="{exact: true}" (click)="isSeminarsActivated = false || isNewsActivated =false || isRejSeminarsActivated = false || navbarOpen = false">Naslovna</a>
      </li>
      <li ngbDropdown class="nav-item" >
        <a  class="nav-link" routerLink= "/bowen-tehnika" routerLinkActive="active" (click)="isSeminarsActivated = false || isNewsActivated =false || navbarOpen = false">Bowen tehnika</a>
      </li>
      <li ngbDropdown class="nav-item" >
        <a  class="nav-link" routerLink= "/prakticari" routerLinkActive="active" (click)="isSeminarsActivated = false || isNewsActivated =false || navbarOpen = false">Bowen praktičari</a>
      </li>
      <li ngbDropdown class="nav-item" >
        <a id="dropdownBasic1" class="nav-link" routerLinkActive="active" #seminars ngbDropdownToggle  [ngClass]="{ 'active' : isSeminarsActivated}" >Bowen obuka</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" (click)="onSeminarsClicked()">
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/seminari/btaa">Bowen Therapy Academy of Australia</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/seminari/obuka">Obuka</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/seminari/nastavni-program">Nastavni program</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/seminari/instruktori">Instruktori</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/seminari/predstojeci-seminari">Predstojeći seminari</a>
        </div>
      </li>
      <li ngbDropdown class="nav-item">
        <a id="dropdownBasic1" class="nav-link" routerLinkActive="active" ngbDropdownToggle [ngClass]="{ 'active' : isNewsActivated}">Novosti i dešavanja</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" (click)="onNewsClicked()">
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/novosti/dogadjaji">Događaji</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/novosti/publikacije">Publikacije</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/novosti/video">Video</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/novosti/galerija">Galerija</a>
        </div>
      </li>
      <!--. <li ngbDropdown class="nav-item">
        <a id="dropdownBasic1" class="nav-link" routerLink="/holisticki-centar" routerLinkActive="active" ngbDropdownToggle>Holistički centar</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a ngbDropdownItem>Bowen tretmani</a>
         <a ngbDropdownItem>Bahove kapi</a>
          <a ngbDropdownItem>Aqua detox</a>
          <a ngbDropdownItem>Reiki</a>
          <a ngbDropdownItem>Rejuvance</a>
          <a ngbDropdownItem>Nameštanje atlasa – prvog vratnog pršljena</a>..
        </div>
      </li>-->
      <!-- <li class="nav-item">
        <a  class="nav-link" routerLink="/rejuvance" routerLinkActive="active" (click)="isSeminarsActivated = false || isNewsActivated =false || isRejSeminarsActivated = false || navbarOpen = false">Rejuvance</a>
      </li> -->
      <li ngbDropdown class="nav-item" >
        <a id="dropdownBasic1" class="nav-link" routerLinkActive="active" #seminars ngbDropdownToggle  [ngClass]="{ 'active' : isRejSeminarsActivated}">Rejuvance obuka</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" (click)="onRejSeminarsClicked()">
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/rejuvance">Rejuvance</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/rejuvance-seminari/obuka">Obuka</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/rejuvance-seminari/instruktori">Instruktori</a>
          <a ngbDropdownItem routerLinkActive="active" routerLink= "/rejuvance-seminari/predstojeci-seminari">Predstojeći seminari</a>
        </div>
      </li>
      <li ngbDropdown class="nav-item">
        <a  class="nav-link" routerLink="/kontakt" routerLinkActive="active" (click)="isSeminarsActivated = false || isNewsActivated =false || isRejSeminarsActivated = false || navbarOpen = false">Kontakt</a>

      </li>
    </ul>
    <!--</div>
    <div  class="col-sm-1 info">
          <span><i class="fab fa-facebook-square fa-2x"></i></span>
    </div>-->

  </div>
  
</nav>
<div>

</div>
