import { InstructorsServices } from './../../seminars/instructors/instructors.services';
import { Instructors } from './../../seminars/instructors/instructors.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rej-instructors',
  templateUrl: './rej-instructors.component.html',
  styleUrls: ['./rej-instructors.component.css']
})
export class RejInstructorsComponent implements OnInit {

  instructors: Instructors[] = [];
  constructor(private instructorsServices: InstructorsServices) { }

  ngOnInit() {
    this.onGetTherapist();
  }

  onGetTherapist() {
    this.instructorsServices.getTherapist().subscribe(responseData => {
      this.instructors = responseData;

      //console.log("[mj]- onGetRejuvanceTherapist", this.instructors);
    });
  }

}
