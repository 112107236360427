<h1>Publikacije</h1>
<div class="card">
  <div class="row" (click)="isVisibleSection8 = !isVisibleSection8">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">1. Podgoričke novine "Dan" o Bowen tehnici</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection8"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection8"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <object data="assets/pdf/podgoricke-novine.pdf" class="pdf-files" *ngIf="isVisibleSection8"></object>
</div>
<div class="card">
  <div class="row" (click)="isVisibleSection7 = !isVisibleSection7">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">2. Bowen Hands, jun 2020. - Novi instruktor u Srbiji</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection7"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection7"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <object data="assets/pdf/BH-June2020.pdf" class="pdf-files" *ngIf="isVisibleSection7"></object>
</div>
<div class="card">
  <div class="row" (click)="isVisibleSection1 = !isVisibleSection1">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p>3. Alexandra Antoniou - intervju magazin Sensa</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection1"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection1"></i></span>

    </div>
  </div>
</div>
<div class="card-body"> <object  data="assets/pdf/antoniou.pdf" class="pdf-files" *ngIf="isVisibleSection1" ></object></div>
<div class="card">
  <div class="row" (click)="isVisibleSection2 = !isVisibleSection2">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">4. Prof. Andrew Zoppos - intervju za
        časopis Bilje & Zdravlje</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection2"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection2"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <object data="assets/pdf/zoposa.pdf" class="pdf-files" *ngIf="isVisibleSection2"></object>
</div>

<div class="card">
  <div class="row" (click)="isVisibleSection3 = !isVisibleSection3">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">5. John Wilks – Embriologija, odomak iz knjige Bowen Tehnika i njene Tajne</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection3"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection3"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <object data="assets/pdf/johnWilks.pdf" class="pdf-files" *ngIf="isVisibleSection3"></object>
</div>
<div class="card">
  <div class="row" (click)="isVisibleSection4 = !isVisibleSection4">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">6. Marioara Hategan - Bowen kroz iskustvo akušer babice</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection4"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection4"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <object data="assets/pdf/akuser.pdf" class="pdf-files" *ngIf="isVisibleSection4"></object>
</div>

<div class="card">
  <div class="row" (click)="isVisibleSection5 = !isVisibleSection5">
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p class="e2e-trusted-url">7. Magazin Pas - Nežnim pokretima do ozdravljenja</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-arrow-circle-down fa-2x" *ngIf="!isVisibleSection5"></i><i
          class="fas fa-arrow-circle-up fa-2x" *ngIf="isVisibleSection5"></i></span>

    </div>
  </div>
</div>
<div class="card-body">
  <img src="assets/pdf/img/3.jpg" *ngIf="isVisibleSection5">
</div>

<div class="card">
  <div class="row" (click)='openNewTab()'>
    <div class="col-lg-10 col-md-10 col-sm-10 col-10">
      <p>8. Blic magazin - Kućni ljubimci i Bowen terapija</p>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-2 arrow">
      <span><i class="fas fa-external-link-alt fa-2x"></i></span>

    </div>
  </div>
</div>


