<h1>BTAA - Bowen Therapy Academy of Australia</h1>



<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="version == 'srb'">
    <object data="assets/pdf/srb-certificate.pdf" class="pdf-files"></object>
  </div>
  <div class="modal-body "  *ngIf="version == 'eng'">
    <object data="assets/pdf/eng-certificate.pdf" class="pdf-files"></object>
  </div>
 
</ng-template>

<div class="container certificate-icon">
  <div class="row">
    <div class="col-lg-6">


      <img class="icon" src="assets/adobe-icon.png" (click)="open(content,'srb')">
      <p>Potvrda o članstvu u BTAA - Srb</p>
    </div>
    <div class="col-lg-6">
      <img class="icon" src="assets/adobe-icon.png" (click)="open(content,'eng')">
      <p>Certification of affiliation - Eng</p>
    </div>
  </div>
</div>
<div class="col-lg-12  instructors-table">
  <img class="profile-image" src="assets/index3.png" alt="..." class="img-thumbnail">
</div>
<div class="container card-body-main">
  <p>
    Osnivač i direktor BTAA, gospodin Osvald Renč (Oswald Rentsch) je Bowen tehniku učio direktno od Toma Bowena, uz kojeg je
    proveo dve i po godine beležeći, crtajući i prateći Tomov rad.</p>
  
    <p>Osvald - Osi (Ossie) i njegova supruga Elen (Elaine) koja je takođe učila kod Toma Bowena, su 1976 godine otvorili naturopatsku
    praksu u Hamiltonu (Zapadna Viktorija), koristeći samo Bowen tehniku kao model manuelne tehnike.</p>
  
    <p>Na zahtev Toma, da nakon njegove smrti podučava ovu tehniku, Osi i Elen su održali 1986. godine prvi seminar u Pertu (Perth). Do 1990. godine Osi i Elen su održavali seminare po celoj Australiji. 
      Uz pomoć Rene Horvud (Renee Horwood), Tomove sekretarice, Osi je sastavio i osmislio priručnike za obuku praktičara Bowen terapijske tehnike, kako su je nazvali u čast Toma Bowena.</p>

    <p>Posvećeni očuvanju tehnike i obezbeđivanju njene nastave u izvornom obliku, 1987. godine osnivaju Australijsku Akademiju Bowen Terapije (BTAA – The Bowen Therapy Academy of Australia), nazvavši tehniku Bowtech – The Bowen Technique.
       Od tada, Osi i Elen su predstavili Bowtech u mnogim zemljama, poštujući obećanje koje je Osi dao Tomu Bowenu na njegovoj samrtnoj postelji, da će širiti njegov rad po celom svetu.</p>
    
    <p>Zdravstveni radnici su impresionirani efikasnošću Bowen tehnike i raznolikošću zdravstvenih problema koji se mogu rešiti ili umanjiti.
       Pošto je ova tehnika toliko efikasna, široki spektar ljudi ju je prihvatio.
    <br><br>
  </p>
</div>