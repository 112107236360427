import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Practitioners } from './practitioners.interface';
import { ServerUrlServices } from 'src/app/shared/server-url.services';

@Injectable()
export class PractitionersServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
  getPractitioner(): Observable<any> {
    return this.http.get<{ [key: string]: Practitioners }>(this.serverUrlServices.serverApi + 'practitioners')
      .pipe(map(responseData => {
        const postsArray: Practitioners[] = [];
        for (const key in responseData.practitioners) {
          if (responseData.practitioners.hasOwnProperty(key)) {
            postsArray.push({ ...responseData.practitioners[key], key });
          }
        }
       console.log("[mj]-getPractitioners", responseData);
        return postsArray;
      })
      )
  }


  // postPractitioner( postData: Practitioners) {


  //   const fd = new FormData();
  //   fd.append('name', postData.name);
  //   fd.append('phone', postData.phone);
  //   fd.append('email', postData.email);
  //   fd.append('country', postData.country);
  //   fd.append('city', postData.city);
  // console.log("postDataPractitioner", fd);
  //  /*   console.log("postData2", postData.image.name);*/
  //   return this.http.post(this.serverUrlServices.serverApi + 'practitioner', fd);
  // }
  postPractitioner( postData: Practitioners) {
    console.log("[mj]-postDataPractitioner1111", postData);

    const fd = new FormData();
    fd.append('name', postData.name);
    fd.append('phone', postData.phone);
    fd.append('email', postData.email);
    fd.append('country', postData.country);
    fd.append('city', postData.city);
    fd.append('city2', postData.city2 === " " ? null : postData.city2);
    fd.append('visible', postData.visible ? '1' : '0');
  console.log("postDataPractitioner", fd);
  console.log("postDataPractitioner", fd);
   /*   console.log("postData2", postData.image.name);*/
    return this.http.post(this.serverUrlServices.serverApi + 'practitioner', fd);
  }


  getEditPractitioner( postData: Practitioners) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    console.log("[mj]-Edit",postData)
    return this.http.put(this.serverUrlServices.serverApi + 'practitioner' + '/' + postData.id, postData, { headers});
  }

  deletePractitioner(id: number) {
    return this.http.delete<{ [id: number]: Practitioners }>(this.serverUrlServices.serverApi + 'practitioner' + '/' + id);
  }

}
