import { ServerUrlServices } from './../../../shared/server-url.services';
import { Instructors } from './../instructors/instructors.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Seminars } from './seminars.interface';

@Injectable()
export class SeminarsServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }

  postContents(postData: Seminars) {
    //console.log("postData", postData);
    return this.http.post<{ [key: string]: Seminars }>(this.serverUrlServices.serverApi + 'seminar', postData);
  }
  getContents(): Observable<any> {
    return this.http.get<{ [key: string]: Seminars }>(this.serverUrlServices.serverApi + 'seminars')
      .pipe(map(responseData => {
        const postsArray: Seminars[] = [];
        for (const key in responseData.seminars) {
          if (responseData.seminars.hasOwnProperty(key)) {
            postsArray.push({ ...responseData.seminars[key], key });
          }
        }
       // console.log("[mj]-getContents",responseData);
        return postsArray;
      })
      )
  }



  getEditContent( postData: Seminars) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.put(this.serverUrlServices.serverApi + 'seminar' + '/' + postData.id, postData, { headers});
  }

  deleteContent(id: number) {
    return this.http.delete<{ [id: number]: Seminars }>(this.serverUrlServices.serverApi + 'seminar' + '/' + id);
  }
}
