import { VideoServices } from './video.service';
import { Component, OnInit } from '@angular/core';
import { Video } from './video.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videos: Video[] = [];
  videosProtectUrl: SafeResourceUrl[] = [];
  constructor(private videoServices: VideoServices, private senitizer: DomSanitizer) { }

  ngOnInit() {

    this.onGetVideos();
    
  }


  onGetVideos() {
    this.videoServices.getVideo().subscribe(responseData => {
      responseData.reverse();
      this.videos = responseData;
    //  console.log("[mj]-getVideos", this.videos);
      this.getVideosUrl();
    });
    
  }

  getVideosUrl() {
   
    for (const key in this.videos) {
     // console.log("[mj]-getVideos2", this.videos[key].video_url);
    this.videosProtectUrl.push(this.senitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+ this.videos[key].video_url));
    }

  }

}
