<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr class="bg-info">
        <th scope="col">Naslov Seminara</th>
        <th scope="col">Grad</th>
        <th scope="col">Seminari</th>
        <th scope="col">Grupa/Broj</th>
        <th scope="col">Instruktori</th>
        <th scope="col">Datum</th>
        <th scope="col">Vreme</th>
        <th scope="col">Izmene</th>
      </tr>

    </thead>


    <tbody>
      <tr>
        <td>{{seminar.title}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.title"><br>
          </div>
        </td>
        <td>{{seminar.town}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.town"><br>
          </div>
        </td>
        <td>{{seminar.seminars}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.seminars"><br>
          </div>
        </td>
        <td>{{seminar.group}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.group"><br>
          </div>
        </td>
        <td>{{seminar.instructors}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.instructors"><br>
          </div>
        </td>
        <td>{{seminar.date}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.date"><br>
          </div>
        </td>
        <td>{{seminar.time}}
          <div *ngIf="isEdit">
            <input type="text" [(ngModel)]="editValues.time"><br>
          </div>
        </td>
        <td>
          <div>
            <div *ngIf="isEdit">
              <button class="btn btn-info" (click)="onSave(seminar.id)">Sačuvaj</button>
              <button class="btn btn-secondary button_padding" (click)="onCancel()">Izađi</button>
            </div>
            <div *ngIf="!isEdit">
              <button class="btn btn-danger" (click)="onDelete(seminar.id)">Obriši</button>
              <button class="btn btn-success button_padding" (click)="onEdit(seminar)">Izmeni</button>
            </div>

          </div>
        </td>
      </tr>

    </tbody>


  </table>
</div>
