<h1>Predstojeći Seminari</h1>
<div class="table-responsive" *ngFor="let title of titles;let i = index;">
  <table class="table table-bordered"  *ngIf="title == 'Rejuvance' && title != null">
  <thead>

    <tr class="table-header">
      <th scope="col">Grad</th>
      <th scope="col">Seminari</th>
      <th scope="col">Grupa/Broj</th>
      <th scope="col">Instruktori</th>
      <th scope="col">Datum</th>
      <th scope="col">Vreme</th>
    </tr>
  </thead>
  <tbody  *ngFor="let seminar of seminars;let i = index;">
    <tr class="white-space" *ngIf="seminar.title == 'Rejuvance'" >
      <td>{{seminar.town}}</td>
      <td>{{seminar.seminars}}</td>
      <td>{{seminar.group}}</td>
      <td >{{seminar.instructors}}
        <span *ngIf="seminar.instructors == 'Stanka Stojkov'"><img   src="assets/star.png"><img   src="assets/star.png"><img   src="assets/star.png"></span>
        <!-- <span *ngIf="seminar.instructors == 'Alexandra Antoniou'"><img   src="assets/star.png"><img   src="assets/star.png"><img   src="assets/star.png"></span> -->
        <span  *ngIf="seminar.instructors == 'Andrew Zoppos'"><img   src="assets/star.png"><img   src="assets/star.png"><img   src="assets/star.png"><img   src="assets/star.png"></span>
      
      </td>
      <td  >{{seminar.date}}</td>
      <td >{{seminar.time}}</td>
    </tr>
  </tbody>
</table>
</div>
