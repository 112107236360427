
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  images = [];

  constructor() { }

  ngOnInit() {
    
    for (let i = 1; i < 27; i++) {
      this.images.push(i+'.jpg');
    }
  }

}
