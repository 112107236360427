<span class="logo" >
  <img src="assets/infoLogo.png">
</span>
<h1>Kontakt</h1>
<div class="container">
  <div class="alert alert-success animated fadeIn" role="alert" *ngIf="isMailSent" >
    Hvala što ste nas kontaktirali!
  </div>
  <div class="col-lg-12 form-container">
    <form class="card" [formGroup]="contactForm" (ngSubmit)="onSubmit()" >
      <div class="form-body">
        <div class="form-header">
          <h2>Pišite nam:</h2>
        </div>
        <div class="form-group">
          <div class="row">

            <div class="col-lg-1">
              <i class="fas fa-user fa-2x"></i>
            </div>
            <div class="col-lg-11">
              <input type="text" class="form-control" formControlName="name" id="name" placeholder="Vaše ime">
              <span class="help-block" *ngIf="!contactForm.get('name').valid && contactForm.get('name').touched" >Popunite polje za ime!</span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">

            <div class="col-lg-1">
              <i class="fas fa-envelope fa-2x"></i>
            </div>
            <div class="col-lg-11">
              <input type="email" class="form-control" formControlName="email" id="email" placeholder="Vaš email">
              <span class="help-block" *ngIf="!contactForm.get('email').valid && contactForm.get('email').touched" >Popunite polje za email!</span>
            </div>
          </div>
        </div>
         <div class="form-group">
            <div class="row">

              <div class="col-lg-1">
                <i class="fas fa-tag fa-2x"></i>
              </div>
              <div class="col-lg-11">
                <input type="text" class="form-control" formControlName="subject" id="subject" placeholder="Naslov">
              </div>
            </div>
          </div>
        <div class="form-group">
          <div class="row">
            <div class="col-lg-1">
              <i class="fas fa-pencil-alt fa-2x"></i>
            </div>
            <div class="col-lg-11">
              <textarea class="form-control" formControlName="content" id="content" placeholder="Vaša poruka"
                rows="5"></textarea>
                <span class="help-block" *ngIf="!contactForm.get('content').valid && contactForm.get('content').touched" >Popunite polje za poruku!</span>
            </div>
          </div>
        </div>
        <div class="submit-button">
          <button type="submit" class="btn btn-layout" [disabled]="!contactForm.valid" >Pošalji</button>
        </div>

      </div>
      <div class="row contact-info">

        <div class="col-lg-6">
            <a class="icon-floating icon-floating-bck">  <i class="fas fa-phone fa-2x"></i></a>
          <p>060/014-99-49</p>
        </div>
        <div class="col-lg-6">
            <a class="icon-floating icon-floating-bck"> <i class="fas fa-envelope fa-2x"></i></a>
          <p>info@bowensrbija.rs</p>
        </div>

      </div>
    </form>

  </div>

  <!--<div class="col-lg-8">
      <div class="div">
        <div class="card">

          <div class="form-body">
            <div class="form-header">
              <h2>Kontakt podaci:</h2>
            </div>
            <div class="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA0s1a7phLN0iaD6-UE7m4qP-z21pH0eSc&q=Eiffel+Tower+Paris+France"
                width="600" height="50" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
            <div class="row contact-info">
              <div class="col-lg-3">
               <a class="icon-floating icon-floating-bck"><i class="fas fa-map-marker-alt fa-2x "></i></a>
                <p>Gračanička 9, Beograd</p>
              </div>
              <div class="col-lg-3">
                  <a class="icon-floating icon-floating-bck">  <i class="fas fa-phone fa-2x"></i></a>
                <p>0643522524</p>
              </div>
              <div class="col-lg-3">
                  <a class="icon-floating icon-floating-bck"> <i class="far fa-clock fa-2x"></i></a>
                <p>PON - PET: 8:30h - 21h | SUB: 11h - 21h</p>
              </div>
              <div class="col-lg-3">
                  <a class="icon-floating icon-floating-bck"> <i class="fas fa-envelope fa-2x"></i></a>
                <p>bowen@gmail.com</p>
              </div>

            </div>

          </div>
        </div>
      </div>

  </div>-->
</div>
