import { Video } from './video.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServerUrlServices } from 'src/app/shared/server-url.services';

@Injectable()
export class VideoServices {

  constructor(private http: HttpClient, private serverUrlServices: ServerUrlServices) {

  }
  getVideo(): Observable<any> {
    return this.http.get<{ [key: string]: Video }>(this.serverUrlServices.serverApi +'videos')
    .pipe(map(responseData => {
      const postsArray: Video[] = [];
      for (const key in responseData.video) {
        if (responseData.video.hasOwnProperty(key)) {
          postsArray.push({ ...responseData.video[key], key });
        }
      }
      return postsArray;
    })
    )
  }


  postVideo( postData: Video) {
    return this.http.post<{ [key: string]: Video }>(this.serverUrlServices.serverApi + 'video', postData);
  }


  getEditVideo( postData: Video) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   /* const fd = new FormData();
    fd.append('image', postData.image);
    fd.append('image_url', postData.image.name);
    fd.append('name', postData.name);
    fd.append('content', postData.content);
    console.log('[mj]-getEditInstructor', fd.has('image'));*/
    return this.http.put(this.serverUrlServices.serverApi + 'video/' + postData.id, postData, { headers});
  }

  deleteVideo(id: number) {
    return this.http.delete<{ [id: number]: Video }>(this.serverUrlServices.serverApi+ 'video/' + id);
  }

}
