import { Component, OnInit } from '@angular/core';
import { Seminars } from './seminars.interface';
import { SeminarsServices } from './seminars.service';

@Component({
  selector: 'app-upcoming-seminars',
  templateUrl: './upcoming-seminars.component.html',
  styleUrls: ['./upcoming-seminars.component.css']
})
export class UpcomingSeminarsComponent implements OnInit {
  seminars: Seminars[] = [];
  titles: string[] = [];
  constructor(private seminarsServices: SeminarsServices) { }

  ngOnInit() {
    this.seminarsServices.getContents().subscribe(responseData => {
      this.seminars = responseData;
      // tslint:disable-next-line: forin
      for (const key in responseData) {
        if (!this.titles.includes(responseData[key].title)) {
          this.titles.push(responseData[key].title);
        }
        if (responseData[key].date.indexOf(',') > -1 || responseData[key].time.indexOf(',')) {
          const item = responseData[key].date.replace(new RegExp(',', 'g'), '\n');
          const item2 = responseData[key].time.replace(new RegExp(',', 'g'), '\n');
          responseData[key].date = item;
          responseData[key].time = item2;
          /*console.log(['mj-Date', responseData[key].date]);
          console.log(['mj-Time', responseData[key].time]);*/
        }
      }
    });
  }

}
